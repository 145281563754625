<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.has-arrow]="sidebarnavItem.submenu.length != 0" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
                <i class="show-menu mdi mdi-dots-horizontal"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
        </li>
    </ul>
</nav>