<div class="col-xs-12">
    <div class="card">
        <div class="card-body">
            <div class="col-md-2">
                <div class="form-group"> 
                    <label for="search">Search Company</label>
                    <input type="text" id="search" [(ngModel)]="searchInput" class="form-control" name="search" (ngModelChange)="getSensorMessageNotUpdatedReport()">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-xs-12">
    <div class="card no-border">
        <div class="card-body">
            <spinner [showSpinner]="isLoading">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th (click)="orderBy('companyName')" class="col-max-200">Company</th>
                                <th (click)="orderBy('siteName')" class="col-max-200">Site</th>
                                <th (click)="orderBy('siteName')" class="col-max-80"></th>
                                <th (click)="orderBy('objectCurrentDate')" class="col-max-200">Updated At</th>
                                <th></th>
                                <th (click)="orderBy('siteName')">Latest Message</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let sensor of reportRows">
                            <td class="col-max-200" title="{{sensor.companyName}}">{{sensor.companyName}}</td>
                            <td class="col-max-200" title="{{sensor.siteName}}">
                                <ng-container *ngIf="hasMultipleLinks(sensor)">
                                    {{sensor.siteName}}
                                </ng-container>
                                <ng-conatiner *ngIf="hasLinks(sensor) && !hasMultipleLinks(sensor)">
                                    <a href="{{ sensor.siteInfoLinks[0].url}}" title="{{sensor.siteName}}"
                                        target="_blank">{{sensor.siteName}}</a>
                                </ng-conatiner>
                            </td>
                            <td class="col-max-80">
                                <goto-site-button *ngIf="hasLinks(sensor) && hasMultipleLinks(sensor)"
                                    [links]="sensor.siteInfoLinks"></goto-site-button>
                            </td>
                            <td class="col-max-200">{{sensor.objectCurrentDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td><i class="fas" [ngClass]="sensorIcon(sensor)"></i></td>
                            <td>{{sensor.objectMessageText}}</td>
                        </tr>
                    </table>
                </div>
            </spinner>
        </div>
    </div>
</div>
