import { Component, Injectable, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BintelIntegrationStatusReport, BintelMultipleDeviceReportRow, BintelQueueStatus } from 'src/app/support/models/bintel-integration-status-report.model';
import { SupportService } from 'src/app/support/services/support.service';

@Component({
    selector: 'bintel-integration-overview',
    templateUrl: './overview.component.html'
})

export class BintelIntegrationOverviewComponent {

    public report: BintelIntegrationStatusReport;
    public devicesAssignedMultipleTimes: BintelMultipleDeviceReportRow[] = [];
    public containersWithoutDevice: string[] = [];
    public unconfiguredDevices: string[] = [];
    public queueStatus: BintelQueueStatus = null;

    public isLoading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService) {
           
            this.queueStatus = new BintelQueueStatus();
            this.queueStatus.queuedItems = 0;
            this.queueStatus.errors = [];

        }


    ngOnInit(): void {
        console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.bintelIntegrationStatusReport()
                    .then(response => {
                        console.log('## Bintel Integration Status Report ##', response);
                        this.report = response;
                        this.containersWithoutDevice = this.report.containersWithoutDevice;
                        this.devicesAssignedMultipleTimes = this.report.devicesAssignedMultipleTimes;
                        this.unconfiguredDevices = this.report.unconfiguredDevices;
                        this.queueStatus = this.report.queueStatus;
                        console.log(this.queueStatus);
                        this.isLoading = false;
                    }).catch(error => {
                    });

            });

    }

    configure(deviceId: string) {
        this.router.navigate(['/integrations/bintel/configure-device'], { queryParams: { carrierNumber: deviceId } });
    }


}
