<div class="row">

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Device settings
            </div>
            <div class="card-body">
                <form autocomplete="off" #configureDeviceSettings="ngForm">

                    <div class="form-group">
                        <label for="carrierNumber">Carrier Number</label>
                        <input type="text" class="form-control" #snfield #carrierNumber="ngModel"
                            [(ngModel)]="deviceSettings.carrierNumber" required name="carrierNumber">
                        <div *ngIf="!carrierNumber.valid && carrierNumber.touched" class="alert alert-danger">
                            Field is required
                        </div>
                    </div>

                    <div class="form-group"> 
                        <label for="sensorTypeId">Sensor Type</label>
                        <select class="form-control" #sensorTypeId="ngModel" [(ngModel)]="deviceSettings.sensorTypeId" id="sensorTypeId" name="sensorTypeId" >
                            <option value="105">208 liter oil drum (sensor type 105)</option>
                            <option value="107">60 liter oil drum (sensor type 107)</option>
                            <option value="118">0-2000mm measurement height (sensor type 118)</option>                                
                        </select>
                    </div>

                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" #gps="ngModel" [(ngModel)]="deviceSettings.allowReconfigure"
                            id="allowReconfigure" name="allowReconfigure">
                        <label class="form-check-label" for="allowReconfigure">Allow reconfigure</label>
                    </div>
                </form>

                <div class="clearfix mt-5">
                    <button class="btn btn-primary btn float-right" [disabled]="!configureDeviceSettings.valid"
                        (click)="configure()">Configure</button>
                </div>
            </div>
        </div>
    </div>
</div>