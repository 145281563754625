<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Device settings
            </div>
            <div class="card-body">
                <form autocomplete="off" #configureDeviceSettings="ngForm">
                    <div class="form-group">
                        <label for="carrierNumber">Carrier Number</label>
                        <input type="text" class="form-control" #carrierNumber="ngModel" [(ngModel)]="deviceSettings.carrierNumber" required name="carrierNumber" placeholder="SE-XXXXXX">
                        <div *ngIf="!carrierNumber.valid && carrierNumber.touched" class="alert alert-danger">
                            Field is required
                        </div>
                    </div>
                    <div class="form-group"> 
                        <label for="sensorTypeId">Sensor Type</label>
                        <select class="form-control" #sensorTypeId="ngModel" [(ngModel)]="deviceSettings.sensorTypeId" id="sensorTypeId" name="sensorTypeId" >
                            <option value="116">DEHOUST PE 4000 L</option>
                            <option value="115">DEHOUST TrioSafe 1500 L</option>
                            <option value="117">Generic 0-3000 mm (3000 L)</option>                           
                            <option value="108">IBC 1000 L</option>
                            <option value="109">IBC 600 L</option>
                            <option value="113">IBC 110 L</option>
                            <option value="114">Schütz LX 1000 L</option>

                        </select>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" #allowReconfigure="ngModel" [(ngModel)]="deviceSettings.allowReconfigure"
                            id="allowReconfigure" name="allowReconfigure">
                        <label class="form-check-label" for="allowReconfigure">Allow reconfigure</label>
                    </div>
                </form>
                <div class="clearfix mt-5">
                    <button class="btn btn-primary float-left" (click)="goBack()">Back</button>
                    <button class="btn btn-primary btn float-right" [disabled]="!configureDeviceSettings.valid" (click)="configureDevice()">Configure</button>
                </div>
            </div>
        </div>
    </div>
</div>
