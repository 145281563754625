import { Component, OnInit } from '@angular/core';
import { PackwiseUnusedCarrierNumber, PackwiseRegisterCarrierNumber } from 'src/app/support/models/packwise-integration-carrier-number.model';
import { SupportService } from 'src/app/support/services/support.service';
import { Router } from '@angular/router';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';

@Component({
	selector: 'app-register-carrier-number',
	templateUrl: './register-carrier-number.component.html'
})
export class PackwiseRegisterCarrierNumberComponent implements OnInit {

	public unusedCarrierNumbers: PackwiseUnusedCarrierNumber[] = [];
	public carrierNumberRegister = new PackwiseRegisterCarrierNumber();

	constructor(
		public supportService: SupportService,
		public router: Router,
		public errorService: DisplayErrorService
	) 
	{ }

	ngOnInit(): void {

		this.supportService.getPackwiseIntegrationCarrierNumber()
			.then(response => {
				console.log('## Packwise Get Carrier Numbers ##', response);
				this.unusedCarrierNumbers = response.carrierNumbers;
			}).catch(error => {
				console.log(error);
			});
	}

	registerNewCarrierNumber(): void {

		this.supportService.addPackwiseIntegrationCarrierNumber(this.carrierNumberRegister)
			.then(response => {
				console.log('## Packwise Register Carrier Numbers ##', response);

				if(!response.successful) {
					alert(response.error);
					this.router.navigate(['/integrations/packwise/register-carrier-number']);	
				}	
				else {
					alert("Carrier number added");
					this.router.navigate(['/integrations/packwise/status']);
				}					
			}).catch(error => {
				this.errorService.display(error);
			});
	}

	goBack(): void {
		this.router.navigateByUrl('integrations');
	}

}
