<div class="modal-header modal-header-error">
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="error-icon">
        <i class="fas fa-exclamation-triangle"></i>
    </div>
    <div class="error-message">
        <span *ngIf="hasErrorObject()">
            {{ errorString() }}
        </span>
        <span *ngIf="!hasErrorObject()">
            {{ message }} 
        </span>
    </div>
</div>
<div class="modal-footer modal-footer-error">
    <div class="button-container">
        <button type="button" class="btn btn-primary" (click)="close()">Close</button>
    </div>
</div>