
export class KingspanRegistredDevices {

    id: number;
    siteInfoGuid: string;
    kingspanSignalmanId: number;
    kingspanAllocationId: number;
    connectionId: number; 
}

export class KingspanRegistredDeviceList { 

    devices: KingspanRegistredDevices[];
}

export class KingspanRegisterDeviceForm {

    siteInfoGuid: string;
    kingspanSignalmanId: string;
    kingspanAllocationId: string;
    connectionId: string;
}

export class KingspanRegisterDeviceConnection  {

    id: number;
    companyId: number;
    companyName: string;
}

export class KingspanRegisterDeviceConnectionList {

    connections: KingspanRegisterDeviceConnection[];

}

