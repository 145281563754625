// import { Route } from '@angular/compiler/src/core';
import { Component, ElementRef, Injectable, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { SupportService } from 'src/app/support/services/support.service';
import { ConfigureDeviceSettings } from './configure-device.model';

@Component({
    selector: 'configure-device',
    templateUrl: './configure-device.component.html'
})

export class BintelConfigureDeviceComponent {

    public deviceSettings = new ConfigureDeviceSettings();
    // @ViewChild('snfield') serialNumberField: ElementRef;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public supportService: SupportService,
        private errorNotification: DisplayErrorService,
        ) {
        //this.deviceSettings.carrierNumber = 'SE-60';


        this.route.queryParamMap
            .subscribe(params => {

                this.deviceSettings = new ConfigureDeviceSettings();
                this.deviceSettings.sensorTypeId = '105';

                let cn = params.get('carrierNumber') || 'SE-';                   
                this.deviceSettings.carrierNumber = cn;
            });

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        //this.serialNumberField.nativeElement.focus();
    }

    configure() : void {
        console.log(this.deviceSettings);

        this.supportService.bintelConfigureDevice(this.deviceSettings)
        .then(response => {            
            alert('Device configured');
            this.router.navigate(['/integrations/bintel/status']);
            
        }).catch(error => {
            this.errorNotification.display(error);
        });

    }


}
