<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Details
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <dl>
                        <dt class="col-sm-3">Company</dt>
                        <dd class="col-sm-9">{{ device?.company || '-'}}</dd>
                        <dt class="col-sm-3">Site</dt>
                        <dd class="col-sm-9">{{ device?.site || '-'}}</dd>
                        <dt class="col-sm-3">Ip Address</dt>
                        <dd class="col-sm-9">{{ device?.ipAddress || '-'}}</dd>
                        <dt class="col-sm-3">Telecom Operator</dt>
                        <dd class="col-sm-9">{{ device?.telecomOperator || '-'}}</dd>
                        <dt class="col-sm-3">Phone Number</dt>
                        <dd class="col-sm-9">{{ device?.phoneNumber || '-'}}</dd>
                        <dt class="col-sm-3">ICC</dt>
                        <dd class="col-sm-9">{{ device?.icc || '-'}}</dd>
                        <dt class="col-sm-3"># Channels</dt>
                        <dd class="col-sm-9">{{ device?.noChannels || '-'}}</dd>
                        <dt class="col-sm-3"># Values</dt>
                        <dd class="col-sm-9">{{ device?.noValues || '-'}}</dd>
                        <dt class="col-sm-3">Last Updated</dt>
                        <dd class="col-sm-9">{{ lastDate | date:'yyyy-MM-dd HH:mm:ss'}}</dd>
                        <dt class="col-sm-3">Daily Count</dt>
                        <dd class="col-sm-9">{{ device?.dailyCount || '-'}}</dd>
                        <dt class="col-sm-3">Error Type</dt>
                        <dd class="col-sm-9">{{ device?.errorType || '-'}}</dd>
                        <dt class="col-sm-3">Time Error</dt>
                        <dd class="col-sm-9">{{ device?.timeError || '-'}}</dd>
                    </dl>
                    <h4 class="mt-3">Links</h4>
                    <ng-container *ngFor="let link of (device?.siteInfoLinks || [])">

                        <a href="{{link.url}}" target="_blank">{{link.description}}</a>


                    </ng-container> 
                </spinner>
            </div>
        </div>
        <button class="btn btn-primary btn-lg" (click)="back()">Back</button>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Raw Data
            </div>
            <div class="card-body">
                {{ device?.rawData }}
            </div>
        </div>
    </div>
</div>