<div id="main-wrapper" dir="ltr" data-theme="light">
    <header class="topbar" data-navbarbg="skin6">
    </header>
    <div class="page-wrapper">
        <div *ngIf="pageInfo.breadcrumb" class="page-breadcrumb">
            <div class="row">
                <div class="col-12 align-self-center">
                    <h4 class="page-title">{{pageInfo?.title}}</h4>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>