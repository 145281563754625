<div class="row">
    <div class="col-xs-12 col-lg-3">
        <button (click)="showAll=!showAll"
            class="btn btn-block btn-primary mb-3">{{ showAll ? 'Show only problems' : 'Show all' }}</button>
    </div>
</div>
<div class="row">
    <div class="col-md-12 align-self-center">
        <div class="section-header">
            <h5>Production</h5>
        </div>
    </div>

    <ng-container *ngFor="let status of systemStatusProd$ | async">
        <div *ngIf="showStatus(status)" class="col-md-3">
            <div class="card text-white" [ngClass]="statusBackground(status)">
                <div class="card-header" title="{{ status.description || '-' }}">
                    {{ status.name }} <span *ngIf="status.status !=='Ok'">- {{ status.status }}</span>
                </div>
                <div class="card-body">
                    <div>{{ status.longStatus }}</div>
                    <span style="opacity: 0.5;">
                        {{(status.updatedAt | date:'yyyy-MM-dd HH:mm:ss') || '-'}} ({{ status.checkCount }})
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="row">
    <div class="col-md-12 align-self-center">
        <div class="section-header">
            <h5>Test</h5>
        </div>
    </div>

    <ng-container *ngFor="let status of systemStatusTest$ | async">
        <div *ngIf="showStatus(status)" class="col-md-3">
            <div class="card text-white" [ngClass]="statusBackground(status)">
                <div class="card-header" title="{{ status.description || '-' }}">
                    {{ status.name }} <span *ngIf="status.status !=='Ok'">- {{ status.status }}</span>
                </div>
                <div class="card-body">
                    <div>{{ status.longStatus }}</div>
                    <span style="opacity: 0.5;">
                        {{(status.updatedAt | date:'yyyy-MM-dd HH:mm:ss') || '-'}} ({{ status.checkCount }})
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="row">
    <div class="col-md-12 align-self-center">
        <div class="section-header">
            <h5>Dev & Other</h5>
        </div>
    </div>

    <ng-container *ngFor="let status of systemStatusOther$ | async">
        <div *ngIf="showStatus(status)" class="col-md-3">
            <div class="card text-white" [ngClass]="statusBackground(status)">
                <div class="card-header" title="{{ status.description || '-' }}">
                    {{ status.name }} <span *ngIf="status.status !=='Ok'">- {{ status.status }}</span>
                </div>
                <div class="card-body">
                    <div>{{ status.longStatus }}</div>
                    <span style="opacity: 0.5;">
                        {{(status.updatedAt | date:'yyyy-MM-dd HH:mm:ss') || '-'}} ({{ status.checkCount }})
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>