import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmActionModalComponent } from 'src/app/shared/confirm-action/confirm-action-modal.component';
import { ConfirmActionComponent } from 'src/app/shared/confirm-action/confirm-action.component';
import { DisplayErrorModalComponent } from 'src/app/shared/display-error/display-error-modal.component';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { SiteEasyQualityReportRow } from '../models/siteEasy-quality-report.model';
import { SupportContractSensorReportRow } from '../models/support-contract-sensor-report.model';
import { SupportService } from '../services/support.service';
import { AddDeviceModalComponent } from './add-device-modal/add-device-modal.component';
import { AddDeviceSettings } from './add-device-modal/add-device.model';
import { dynamicSort } from 'src/app/shared/functions/sort';
import { UpdateSiteEasyInfoComponent } from './update-siteEasy-info-modal/update-siteEasy-info.component';
import { UpdateSiteEasyQualityReportInfo } from '../models/siteEasy-update-quality-report-info.model';


@Component({
    selector: 'SiteEasy-quality-report',
    templateUrl: './siteEasy-quality-report.component.html',
})
export class SiteEasyQualityReportComponent implements OnInit {

    public reportRows: SiteEasyQualityReportRow[];
    public isLoading: boolean = true;
    private currentOrder: string = 'objectCurrentDate';
    public updatedInfo = new UpdateSiteEasyQualityReportInfo();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorNotification: DisplayErrorService,
        public modalService: NgbModal,
        private supportService: SupportService) {
    }

    ngOnInit(): void {

        this.route.queryParamMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.getSiteEasyQualityReport()
                    .then(response => {
                        console.log('## SiteEasy Quality Report ##', response);
                        this.reportRows = response;
                        this.isLoading = false;
                    }).catch(error => {
                    });
            });

    }

    removeDevice(device: SiteEasyQualityReportRow) {
        let confirmText = `Are you sure you want to remove ${device.serialNumber} from testing?`;

        if (device.quality !== 'A') {
            confirmText = `Are you sure you want to remove ${device.serialNumber} from testing? \n\rIt has quality ${device.quality} but it should be A for delivery.`;
        }

        const modalRef = this.modalService.open(ConfirmActionModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.componentInstance.message = confirmText;
        modalRef.result.then((result) => {
            if (result === true) {

                this.supportService.removeSiteEasyFromQualityReport(device.serialNumber).then(() => {
                    this.router.navigate(['/siteEasy-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
                }, (error) => {
                    console.warn(error);
                });

            }
        });

    }

    addDevice() {
        const modalRef = this.modalService.open(AddDeviceModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.result.then((device: AddDeviceSettings) => {

            console.log(device);

            this.supportService.addSiteEasyToQualityReport(device).then(() => {
                this.router.navigate(['/siteEasy-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
            }, (response) => {
                this.errorNotification.display(response.error);
            });

        }, () => {});

    }

    orderBy(property: string, skipFlip: boolean = false) {

        if (this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if (!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ?
                    property : '-' + property;
            }
        }
        this.reportRows = this.reportRows.sort(dynamicSort(this.currentOrder));
    }

    updateInfo(serialNumber: string, currentInfo: string) {
        
        const modalRef = this.modalService.open(UpdateSiteEasyInfoComponent,
            { 
                backdrop: 'static', 
                centered: true, 
            });

        modalRef.componentInstance.updatedInfo = currentInfo;

        modalRef.result.then((info: string) => {

            this.updatedInfo.serialNumber = serialNumber;
            this.updatedInfo.info = info;

            this.supportService.updateSiteEasyQualityReportInfo(this.updatedInfo).then(() => {
                this.router.navigate(['/siteEasy-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
            }, (response) => {
                this.errorNotification.display(response.error);
            });

        }, () => {});
    
    }

}




