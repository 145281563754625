import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestService {

    constructor(private httpClient: HttpClient ) {
    }

    get<T>(url: string, headers?: HttpHeaders): Promise<T> {
        // When createing the result type assertion is used, this will not use the constructor!T

        return this.toPromise(this.httpClient.get<T>(url, { headers: headers }))
            .then(response => {
                try {
                    let result = response as T;
                    return result;
                } catch (err) {
                    console.warn('Could not convert json to object');
                    throw err;
                }
            });
    }

    post<T>(url: string, data: any, headers?: HttpHeaders): Promise<T> {
        return this.toPromise(this.httpClient.post<T>(url, data, { headers: headers }))
            .then(response => {
                try {
                    let result = response as T;
                    return result;
                } catch (err) {
                    console.warn('Could not convert json to object');
                    throw err;
                }
            });
    }

    put<T>(url: string, data: any): Promise<T> {
        return this.toPromise(this.httpClient.put<T>(url, data))
            .then(response => {
                try {
                    let result = response as T;
                    return result;
                } catch (err) {
                    console.warn('Could not convert json to object');
                    throw err;
                }
            });
    }

    delete(url: string, headers?: HttpHeaders): Promise<Object> {
        return this.toPromise(this.httpClient.delete(url, { headers: headers }))
            .then(response => {
                try {
                    let res = response as Object;
                    return res;
                } catch (err) {
                    console.warn('Could not convert json to object');
                    throw err;
                }
            })
    } 

    toPromise<T>(o: Observable<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            let value: any;
            o.subscribe((x: T) => value = x, (err: any) => reject(err), () => resolve(value));
        }) as Promise<T>;
    } 

}