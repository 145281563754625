import { Injectable } from '@angular/core';

@Injectable()
export class HelperConfigService {

    private _showTooltips: boolean = false;
      
    constructor() {
        console.log('HelperConfigService()');
    }

    toggleTooltips() {
        this._showTooltips = !this._showTooltips;
    }

    hideTooltips() : boolean {
        return this._showTooltips;
    }

}
