<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <form #removeCarrierNumber="ngForm" novalidate>
                    <div class="form-group">
                        <label for="carrierNumber">Carrier number</label>  
                        <input type="text" class="form-control" id="carrierNumber" name="carrierNumber" #carrierNumber="ngModel" [(ngModel)]="carrierNumberDelete.carrierNumber" placeholder="SE-XXXXXX" required>   
                        <div *ngIf="carrierNumber.errors?.required && carrierNumber.touched" class="alert alert-danger">
                            Field is required.   
                        </div>                          
                    </div>
                    <div class="form-group">
                        <label for="reason">Reason</label> 
                        <input type="text" class="form-control" id="reason" name="reason" #reason="ngModel" [(ngModel)]="carrierNumberDelete.deleteText" required>
                        <div *ngIf="reason.errors?.required && reason.touched" class="alert alert-danger">
                            Field is required.   
                        </div>
                    </div>
                    <div>
                        <p>Use this to permenantly delete a carrier number. If, for an example, a label would break and no longer can be used. The number you delete will no longer be in the system.</p>
                    </div>
                    <div class="clearfix mt-5"> 
                        <button class="btn btn-primary float-left" (click)="goBack()">Back</button>
                        <button class="btn btn-primary btn float-right" [disabled]="!removeCarrierNumber.valid"(click)="deleteCarrierNumber()">Remove</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
