
<div class="row">
    <div class="col-md-3">
        <div class="card card no-border">
            <div class="card-body">
                <div class="align-self-center">
                    <h6 class="text-muted mt-2 mb-0">Old data - Support Sensors</h6>
                    <h2 *ngIf="objectStatisticReport$ | async as report; else loadingData">
                        {{ report.supportObjectOutdatedCount }} of {{ report.supportObjectCount }}
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card card no-border">
            <div class="card-body">
                <div class="align-self-center">
                    <h6 class="text-muted mt-2 mb-0">Old data - Site Info</h6>
                    <h2 *ngIf="objectStatisticReport$ | async as report; else loadingData">
                        {{ report.objectOutdatedCount }} of {{ report.objectCount }}
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingData>
    <i class="fas fa-circle-notch fa-spin"></i>
</ng-template>
