import { Injectable, Injector } from '@angular/core';

import {
    HttpResponse,
    HttpErrorResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    private authService: AuthService;

    constructor(
        private inj: Injector
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // This prevents cyclic dependancy between authService and authTokenInterceptor
        this.authService = this.inj.get(AuthService)

        if(request.headers.has('Authorization')) {
            return next.handle(request);
        }

        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authService.getToken()}`,
                ExternalKey: this.authService.getExternalKey()
            }
        });

        return next.handle(request);
    }
}