<div class="row">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">
                Bintel
            </div>
            <div class="card-body">
                <ul>
                    <li><a href="/integrations/bintel/status">Status</a></li>
                    <li><a href="/integrations/bintel/configure-device">Manually configure device</a></li>
                    <hr>
                    <li><a href="/assets/docs/bintel/bintel-sensor-documentation.pdf" target="_blank">Instructions to manually configure Bintel-sensor </a><i class="fas fa-file-pdf"></i></li>                    
                </ul>
                <div class="alert alert-secondary" role="alert">
                    Bintel sensors are automatically configured. Only use manual configuration for debugging purposes.
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Packwise
            </div>
            <div class="card-body">
                <ul>
                    <li><a href="/integrations/packwise/status">Status</a></li>
                    <li><a href="/integrations/packwise/register-carrier-number">Register carrier number</a></li>
                    <li><a href="/integrations/packwise/remove-carrier-number">Remove carrier number</a></li>
                    <li><a href="/integrations/packwise/configure-device">Configure Device</a></li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                Kingspan
            </div>
            <div class="card-body">
                <ul>
                    <li><a href="/integrations/kingspan/status">Status</a></li>
                    <li><a href="/integrations/kingspan/register-device">Register device</a></li>
                    <li><a href="/integrations/kingspan/device-list">Device list</a></li>
                    <li><a href="/integrations/kingspan/pending-devices">Pending devices</a></li>
                    <hr>
                    <li><a href="/assets/docs/kingspan/kingspan-watchman-anywhere-api-documentation.pdf" target="_blank">Watchman anywhere API documentation </a><i class="fas fa-file-pdf"></i></li>
                </ul>
            </div>
        </div>
    </div>
</div>