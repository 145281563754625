import { Component, OnInit, HostListener } from '@angular/core';
import { AlarmSubscriber } from '../../models/alarm-subscriber.model';
import { SupportService } from '../../services/support.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';

@Component({
	selector: 'app-add-alarm-subscriber',
	templateUrl: './add-alarm-subscriber.component.html',
})

export class AddAlarmSubscriberComponent implements OnInit {

	@HostListener('document:keydown.enter', ['$event'])
	onKeydownHandler(event: KeyboardEvent) {
		document.getElementById('confirmButton').click();
	}

	public alarmSubscriber = new AlarmSubscriber();

	constructor(
		public supportService: SupportService,
		private route: ActivatedRoute,
		public router: Router,
		public errorNotification: DisplayErrorService

	) {}

	ngOnInit(): void {
	}

	addAlarmSubscribers(): void {

		this.supportService.addAlarmSubscriber(this.alarmSubscriber)
			.then(response => {
				alert('New email registered');
				this.router.navigateByUrl('alarm-subscribers');

			}).catch(error => {
				this.errorNotification.display(error);
			});
	}

}
