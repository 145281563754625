<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">             
                <form #alarmSubscribing="ngForm" novalidate>
                    <div class="form-group">
                        <label for="subscriberEmail">E-mail</label>
                        <input type="text" class="form-control" id="subscriberEmail" required name="subscriberEmail" #subscriberEmail="ngModel" [(ngModel)]="alarmSubscriber.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                   
                        <div *ngIf="subscriberEmail.errors?.required && subscriberEmail.touched" class="alert alert-danger">
                            Field is required
                        </div>
                        <div *ngIf="subscriberEmail.errors?.pattern && !subscriberEmail.errors?.required && subscriberEmail.touched" class="alert alert-danger">
                            Not a valid e-mail
                        </div>                          
                    </div>    
                </form>
                <div class="clearfix mt-5">
                    <button class="btn btn-primary btn float-right" id="confirmButton" [disabled]="!alarmSubscribing.valid"
                        (click)="addAlarmSubscribers()">Add to list</button>
                </div>
            </div>
        </div>
    </div>
</div>
