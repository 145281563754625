<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{ 'show-search': showSearch }">
            <input type="text" class="form-control" placeholder="Search &amp; enter" />
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form>
    </li> -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <li (click)=toggleHelp() class="nav-item">
        <a class="nav-link text-muted" href="javascript:void(0)">
            <i [ngClass]="!helperConfig.hideTooltips() ? 'notice-green' : ''" class="fas fa-question-circle"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i style="font-size: 24px;" class="mdi mdi-account"></i>
        </a>
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
            <!-- <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a> -->
            <a class="dropdown-item" href="https://useradmin.mcd.se">
                <i class="ti-lock m-r-5 m-l-5"></i> Auth Admin (Prod)</a>
            <a class="dropdown-item" href="https://testuseradmin.mcd.se">
                <i class="ti-lock m-r-5 m-l-5"></i> Auth Admin (Test)</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>