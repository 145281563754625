<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <form #registerCarrierNumber="ngForm" novalidate>
                    <div class="form-group">
                        <label for="packwiseId">Packwise Id</label> 
                        <input type="text" id="packwiseId" class="form-control" name="packwiseId" #packwiseId="ngModel" [(ngModel)]="carrierNumberRegister.packwiseId" required>
                        <div *ngIf="packwiseId.errors?.required && packwiseId.touched" class="alert alert-danger">
                            Field is required. 
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="carrierNumber">Carrier number</label>
                        <select  id="carrierNumber" class="form-control" name="carrierNumber" [(ngModel)]="carrierNumberRegister.carrierNumber" #carrierNumber="ngModel" required>
                            <option *ngFor="let carrierNumber of unusedCarrierNumbers" value="{{carrierNumber}}" class="form-control">{{carrierNumber}}</option>
                        </select>
                        <div *ngIf="carrierNumber.errors?.required && carrierNumber.touched" class="alert alert-danger">
                            Field is required. 
                        </div>    
                    </div>
                </form>
                <div class="clearfix mt-5"> 
                    <button class="btn btn-primary float-left" (click)="goBack()">Back</button>
                    <button class="btn btn-primary btn float-right" [disabled]="!registerCarrierNumber.valid"(click)="registerNewCarrierNumber()">Register</button>
                </div>
            </div>
        </div>
    </div>
</div>
