<div class="modal-header">
    <h4 class="modal-title">Add device</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form autocomplete="off" #addDeviceSettings="ngForm">
        <div class="form-group">
            <label for="serialNumber">Serial Number</label>
            <input type="text" class="form-control" #snfield #serialNumber="ngModel" [(ngModel)]="deviceSettings.serialNumber"
                required name="serialNumber">
            <div *ngIf="!serialNumber.valid && serialNumber.touched" class="alert alert-danger">
                Field is required
            </div>
        </div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" #sensorType="ngModel" [(ngModel)]="deviceSettings.sensorType" id="sensorType" name="sensorType">
            <label class="form-check-label" for="sensorType">Rochester</label>
        </div>
    </form>
    <div class="clearfix mt-5">
        <button class="btn btn-primary btn float-right" id="confirmButton" [disabled]="!addDeviceSettings.valid" (click)="add()">Add</button>
        <button class="btn btn-secondary btn float-right mr-1" (click)="cancel()">Cancel</button>
    </div>
</div>