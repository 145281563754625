import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { HelperConfigService } from './helper-config.service';

@Component({
    selector: 'helper',
    templateUrl: './helper.component.html'
})
export class HelperComponent implements OnInit {
    
    @Input('text') text: string;
   
    constructor(public configService: HelperConfigService) { }

    ngOnInit() {
    }

}