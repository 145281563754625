import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Status } from '../models/client.model';
import { ObjectStatisticReport } from '../models/object-statistic-report.model';
import { SystemSummary } from '../models/system-summary.model';
import { MonitoringService } from '../services/monitoring.service';
import { SupportService } from '../services/support.service';
import { SystemStatusComponent } from '../system-status/system-status.component';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
})
export class SupportDashboardComponent implements OnInit {

    public systemStatus$: Observable<SystemSummary>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private monitoringService: MonitoringService,
        private supportService: SupportService) {

    }

    ngOnInit(): void {
        this.systemStatus$ = this.monitoringService.systemSummary$;
        

    }

    statusBackground(systemSummary: SystemSummary) : string {

        switch(systemSummary.status) {
            case 'Ok': 
                return 'bg-success';
            case 'Warning': 
                return 'bg-warning';
            case 'Error':
                return  'bg-danger';
            default: 
                return 'bg-secondary';
        }

    }

    // gotoStatus() {
    //     this.router.navigate(['system-status']);
    // }

    statusNotification(systemSummary: SystemSummary) : boolean {
        return systemSummary.notifyUser();
    }

    ngOnDestroy() {

    }

}



