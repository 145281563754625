import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ObjectStatisticReport } from '../../models/object-statistic-report.model';
import { SupportService } from '../../services/support.service';

@Component({
    selector: 'object-statistics',
    templateUrl: './object-statistics.component.html',
})
export class ObjectStatisticsComponent implements OnInit {

    public objectStatisticReport$: Observable<ObjectStatisticReport>;
    private stopPolling = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private supportService: SupportService) {

    }

    ngOnInit(): void {

        this.objectStatisticReport$ = timer(1, 60000).pipe(
            switchMap(() => this.supportService.getObjectStatisticReport()),
            map((x) => {
                return x[0]; // Where does this array come from?
            }),
            retryWhen(errors => errors.pipe(delay(60000))),
            takeUntil(this.stopPolling)
        );


    }

    ngOnDestroy() {
        this.stopPolling.next();
        this.stopPolling.complete();
    }

}



