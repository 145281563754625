<div class="modal-header">
    <h4 class="modal-title">Update info</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form autocomplete="off" #updateInfo="ngForm">
        <div class="form-group">
            <label for="info">Device Info</label>
            <input type="text" class="form-control" #infoField #info="ngModel" [(ngModel)]="updatedInfo" id="info" name="info" required>
        </div>
    </form>
    <div class="clearfix mt-5">
        <button class="btn btn-primary btn float-right" id="confirmButton" (click)="add()">Add</button>
        <button class="btn btn-secondary btn float-right mr-1" (click)="cancel()">Cancel</button>
    </div>
</div>
