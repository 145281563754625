export class Company {
    companyId: number;
    companyName: string;
}

export class InvoiceResponsible {
    contactId:	number;			
	contactName: string;
    contactListName: string;
	hasRentalContract: boolean;	
}

export class Equipment {
    carrierId: number;
    description: string; 
}

export class ValidateCarrierRequest {
    carrierTypeId: number;
    serialNumbers: SerialNumber[];
}

export class ValidateCarrierResponse {
    serialNumberId: number;
    serialNumber: string;
    isValidationOk: boolean;
    validationError: string;
}

export class SerialNumberListItem {
    serialNumberId: number;
    serialNumber: string;
    isValidationOk: boolean;
    validationError: string;
}

export class SerialNumberFormSetting {
    companyId: string;
    contactId: string;
    hasRentalContract: boolean;
    carrierTypeId: string;
    singleSerialNumber: string; 
}

export class SerialNumber {
    serialNumber: string;
}

export class WarehouseImportSetting {
    companyId: number;
    invoiceResponsibleId: number;
    carrierTypeId: number;
    hasRentalContract: boolean;
    serialNumbers: SerialNumber[];
}

