<div class="col-xs-12">
    <div class="card">
        <div class="card-body">
            <div class="col-md-2"> 
                <div class="form-group">    
                    <h6>Show:</h6>
                    <input type="radio" value="lowVoltage" name="sortOption" [(ngModel)]="sortOption" (change)="sortBy()"> Low voltage<br/>
                    <input type="radio" value="outOfOrder" name="sortOption" [(ngModel)]="sortOption" (change)="sortBy()"> Out of order<br/>
                    <input type="radio" value="all" name="sortOption" [(ngModel)]="sortOption" (change)="sortBy()"> All <br/>
                </div>
                <div class="form-group">   
                    <label for="search">Search Company</label>
                    <input type="text" id="search" [(ngModel)]="searchInput" class="form-control" name="search" (ngModelChange)="getBatteryStaus()">
                </div>
            </div>  
        </div>
    </div>
</div>

<div class="col-xs-12">
    <div class="card no-border">
        <div class="card-body">
            <spinner [showSpinner]="isLoading">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th (click)="orderBy('companyName')">Company</th>
                                <th (click)="orderBy('siteName')">Site</th> 
                                <th></th>
                                <th (click)="orderBy('sensorNumber')"> Sensor Number</th>
                                <th (click)="orderBy('severity')" >Severity</th>
                                <th (click)="orderBy('batteryVoltage')">Battery Voltage</th>
                                <th (click)="orderBy('lastContact')">Last update</th>
                                <th (click)="orderBy('message')">Message</th>
                            </tr>
                        </thead>
                        <tr *ngFor="let battery of reportRows" class="tableHover">
                            <td>{{battery.companyName}}</td>
                            <td>
                                <ng-container *ngIf="hasMultipleLinks(battery)">
                                    {{battery.siteName}}
                                </ng-container>
                                <ng-conatiner *ngIf="hasLinks(battery) && !hasMultipleLinks(battery)">
                                    <a href="{{ battery.siteInfoLinks[0].url}}" title="{{battery.siteName}}"
                                        target="_blank">{{battery.siteName}}</a>
                                </ng-conatiner>
                            </td>
                            <td class="col-max-80">
                                <goto-site-button *ngIf="hasLinks(battery) && hasMultipleLinks(battery)"
                                    [links]="battery.siteInfoLinks"></goto-site-button>
                            </td>
                            <td>{{battery.sensorNumber}}</td>  
                            <td><i [ngClass]="severityLevel(battery)"></i></td>
                            <td> 
                                <ng-container *ngIf="battery.severity != 4; else dontShowVoltage">
                                    {{battery.batteryVoltage}}
                                </ng-container>
                                <ng-template #dontShowVoltage>
                                   ---
                                </ng-template>
                            </td>
                            <td>{{battery.lastContact | date:'yyyy-MM-dd HH:mm'}}</td>
                            <td>{{battery.message}}</td>   
                        </tr>
                    </table>
                </div>
            </spinner>
        </div>
    </div>
</div>
