import { Component, Injectable, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SupportService } from '../services/support.service';

@Component({
    selector: 'integrations-overview',
    templateUrl: './overview.component.html'
})

export class IntegrationsOverviewComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService) {

    }


    ngOnInit(): void {
        console.log("ngOnInit()");
    }


}
