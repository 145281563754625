export class SystemSummary {
    status: string;
    updatedAt: Date;
    description: string;

    constructor() {
        this.status = 'Not Initialized';
        this.updatedAt = new Date(2000, 1, 1);
    }

    notifyUser() : boolean {

        if(this.status === 'Ok')
            return false;

        return true;
        
    }
}
