import { Component, OnInit, Input, SimpleChanges} from '@angular/core';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    
    @Input() public showSpinner = false;

    constructor() { }

    ngOnInit() {
        
    }
    ngOnChanges(changes: SimpleChanges){
        console.log(changes);
        // do something here
    }
}