import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GotoSiteModal } from 'src/app/shared/goto-site-modal/goto-site-modal.component';
import { SupportContractSensorReportRow } from '../../models/support-contract-sensor-report.model';
import { SupportService } from '../../services/support.service';
import { dynamicSort } from '../../../shared/functions/sort'

@Component({
    selector: 'sensor-list',
    templateUrl: './sensor-list.component.html',
})
export class SensorListComponent implements OnInit {

    public sensorReport$: Observable<SupportContractSensorReportRow[]>;
    public supportSensors: SupportContractSensorReportRow[];
    private stopPolling = new Subject(); // Stop polling server
    private currentOrder: string = 'objectCurrentDate';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private supportService: SupportService) {
    }

    ngOnInit(): void {

        this.sensorReport$ = timer(1, 25000).pipe(
            switchMap(() => this.supportService.getSupportContractSensorReport()),
            map((x) => {
                console.log('Support Sensors', x);
                return x;
            }),
            retryWhen(errors => errors.pipe(delay(30000))),
            takeUntil(this.stopPolling)
        );

        this.sensorReport$.subscribe(x => {
            this.supportSensors = x;
            this.orderBy(this.currentOrder, true);
        });    
    }

    checkIfDateHasPassed(date: string): boolean {

        let dateOfToday = new Date();
        let dateHideUntil = new Date(date);

        if(dateHideUntil > dateOfToday) {
            return false;
        }    
        return true;
    }

    showDateHasPassedAction(date: Date): string {

        let dateOfToday = new Date();
        let dateHideUntil = new Date(date);

        if(dateOfToday > dateHideUntil){
            return 'fas fa-exclamation-triangle';
        }
    }

    orderBy(property: string, skipFlip: boolean = false) {
        
        if(this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if(!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ? 
                property : '-' + property;
            }
        }

        this.supportSensors = this.supportSensors.sort(dynamicSort(this.currentOrder));
    }

    sensorIcon(sensor: SupportContractSensorReportRow): string {
        switch (sensor.objectMessageStatusID) {
            case 6:
                return 'fa-wrench';
            case 5:
                return 'fa-exclamation-circle';
            case 4:
                return 'fa-phone fa-flip-horizontal';
            case 3:
                return 'fa-phone-slash fa-flip-horizontal';
            case 2:
                return ''; // ?
            case 1:
                return ''; // ?
            default:
                return '';
        }
    }

    hasLinks(sensor: SupportContractSensorReportRow) {
        if(!sensor.siteInfoLinks || sensor.siteInfoLinks.length == 0)
            return false;
        return true;
    }

    hasMultipleLinks(sensor: SupportContractSensorReportRow) {
        return sensor.siteInfoLinks.length > 1;
    }

    ngOnDestroy() {
        this.stopPolling.next();
        this.stopPolling.complete();
    }

}



