import { Component, HostListener, ViewChild, ElementRef  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-tek822-info',
  templateUrl: './update-tek822-info.component.html'
})
export class UpdateTek822InfoComponent {

  public updatedInfo: string;

  @ViewChild('infoField') infoField: ElementRef; 

  @HostListener('document:keydown.enter', ['$event'])
      onKeydownHandler(event: KeyboardEvent) {  
          document.getElementById('confirmButton').click();   
  }

  constructor(
      public modal: NgbActiveModal
      ) {
  }

  ngOnInit(): void {
      
  }

  ngAfterViewInit(): void {
      this.infoField.nativeElement.focus();
  }

  add() {
      this.modal.close(this.updatedInfo);
  }

  cancel() {
      this.modal.dismiss();
  }

}
