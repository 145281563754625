<div class="row">
    <div class="col-lg-8">
        <div class="card">
            <div class="card-body">
                <form #importSerialNumber="ngForm" novalidate>
                    <div class="formSettings">
                        <div class="form-group">
                            <label for="companyId">Company</label>
                            <select class="form-control" name="companyId" id="companyId" #companyId="ngModel" [(ngModel)]="serialNumberFormSettings.companyId" (change)="getInvoiceResponsible()"  required>
                                <option value="">Choose company</option>
                                <option *ngFor="let company of companies" value="{{company.companyId}}">{{company.companyName}}</option>
                            </select>                                        
                            <div class="form-control" *ngIf="!companyId.valid && companyId.touched" class="alert alert-danger">
                                Company is required
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="invoiceResponsible">Invoice recipient</label>
                            <select class="form-control" name="contactId" id="contactId" #contactId="ngModel" [(ngModel)]="serialNumberFormSettings.contactId" (change)="setHasRentalContract()" required>
                                <option value="">Choose invoice recipient</option>
                                <option *ngFor="let responsible of invoiceResponsibles" value="{{responsible.contactId}}">{{responsible.contactListName}}</option>
                            </select>  
                            <div *ngIf="!contactId.valid && contactId.touched" class="alert alert-danger">
                                Invoice recipient is required
                            </div>                                                 
                        </div>
                        <div class="row checkboxInput">
                            <div class="col-12">
                                <input type="checkbox" id="hasRentalContract" name="hasRentalContract" #hasRentalContract="ngModel" [(ngModel)]="serialNumberFormSettings.hasRentalContract" [disabled]="!rentalContractIsEnabled">
                                <label for="hasRentalContract">Rental</label>
                            </div>
                            <div class="col-12">
                                <small>(Rental can only be selected if the invoice recipient has a rental contract)</small>
                            </div>                    
                        </div>
                        <div class="form-group">
                            <label for="carrierId">Equipment</label>
                            <select class="form-control" name="carrierId" id="carrierId" #carrierId="ngModel" [(ngModel)]="serialNumberFormSettings.carrierTypeId" required>
                                <option value="">Choose equipment</option>    
                                <option *ngFor="let carrier of equipments" value="{{carrier.carrierId}}">{{carrier.description}}</option>
                            </select>
                            <div *ngIf="!carrierId.valid && carrierId.touched" class="alert alert-danger">
                                Equipment is required
                            </div>                  
                        </div> <!-- END FORMSETTINGS -->    
                    </div>             
                    <div class="fileUploadWrapper">
                        <h4 class="fileUploaderHeader">Serial numbers</h4>
                        <label for="serialNumber">Add single serialnumber</label>
                        <div class="form-group row">
                            <div class="col-6">                               
                                <input class="form-control" type="text" #serialNumber="ngModel" id="serialNumber" name="serialNumber" [(ngModel)]="serialNumberFormSettings.singleSerialNumber">
                            </div>
                            <div class="col-6">
                                <button class="btn btn-secondary" (click)="addSerialNumber()">Add serial number</button>
                            </div>                         
                        </div>
                        <div class="fileUploadInput">
                            <p>Upload serial number file. Accepted format: csv.</p>
                            <input type="file" id="fileUpload" accept=".csv" (change)="uploadFile($event)" hidden>
                            <button class="btn btn-primary" (click)="fileButtonEvent()">Upload file</button>
                        </div>
                        <div class="table-responsive">                            
                            <table class="table table-sm">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Serial number</th>
                                        <th>Validation is ok</th>
                                        <th>Error message</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let serialNumber of serialNumberList">
                                        <td>{{serialNumber.serialNumber}}</td>
                                        <td><i [ngClass]="checkValidationStatus(serialNumber.isValidationOk)"></i></td>
                                        <td>{{serialNumber.validationError}}</td>
                                        <td><button class="btn btn-danger btn-sm" (click)="removeSerialNumber(serialNumber.serialNumberId)">Remove</button></td>
                                    </tr>
                                </tbody>
                            </table>                                                 
                        </div>                       
                        <spinner [showSpinner]="isLoading">       
                            <div *ngIf="showErrorMessage" class="alert alert-danger" role="alert">
                                <span>There are invalid serial numbers in the list</span>
                            </div> 
                        </spinner>                                                                            
                        <div class="clearfix mt-5">
                            <button class="btn btn-secondary btn-sm float-right" (click)="validateCarrier()" [disabled]="!carrierId.valid || serialNumberList.length === 0">Validate serial number</button>
                        </div>   
                    </div>                     
                    <div *ngIf="validationError" class="alert alert-danger" role="alert">
                        <span>{{validationError}}</span>
                    </div>
                    <div class="clearfix mt-5">
                        <button class="btn btn-primary float-right" (click)="submit()" [disabled]="!(importSerialNumber.valid && importButtonEnable)">Import</button>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>
