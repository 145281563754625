<div class="col-xs-12">
    <ng-container *ngIf="systemStatus$ | async as systemStatus; else connectionProblem">
        <div class="card text-white" [ngClass]="statusBackground(systemStatus)">
            <div class="card-body">
                {{ systemStatus.description }} <a class="notifiction-link" *ngIf="statusNotification(systemStatus)"
                    routerLink="/system-status">Go to System
                    Status</a>
            </div>
        </div>
    </ng-container>

    <ng-template #connectionProblem>
        <div class="card" class="bg-danger">
            <div class="card-body">
                Could not load system status summary, check System Status.
            </div>
        </div>
    </ng-template>

</div>

<object-statistics></object-statistics>

<sensor-list></sensor-list>