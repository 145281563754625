import { Component, OnInit } from '@angular/core';
import { KingspanIntegrationStatusReport } from 'src/app/support/models/kingspan-integration-status-report.model';
import { SupportService } from 'src/app/support/services/support.service';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html'
})
export class KingspanIntegrationOverviewComponent implements OnInit {

  public report: KingspanIntegrationStatusReport;
  public isLoading = true;

  constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService){        
         }

 

  ngOnInit(): void {

    console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.KingspanIntegrationStatusReport()
                    .then(response => {
                        console.log('## Kingspan Integration Status Report ##', response);
                        this.report = response;
                        this.isLoading = false;
                    }).catch(error => {
                    });
            });
  }

}
