import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';


@Component({
    selector: 'process-logout',
    templateUrl: './process-logout.component.html'
})
export class ProcessLogoutComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {


    }

    ngOnInit(): void {
        console.log('ngOnInit()');
        this.processLogout();
    }

    processLogout() {
        console.log('processLogout()');

        this.authService.clearData();
        this.router.navigate(['login'])
    }

}
