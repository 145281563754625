<div class="col-xs-12">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary float-right" (click)="addDevice()">Add device</button>
        </div>
    </div>
</div>
<div class="col-xs-12">
    <div class="card no-border">
        <div class="card-body">
            <spinner [showSpinner]="isLoading">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th (click)="orderBy('serialNumber')">S/N</th>
                                <th (click)="orderBy('status')">Status</th>
                                <th (click)="orderBy('info')">Info</th>
                                <th></th>
                                <th (click)="orderBy('quality')">Quality</th>
                                <th (click)="orderBy('values')">Values</th>
                                <th (click)="orderBy('valueStatus')">Value Status</th>
                                <th (click)="orderBy('positions')">Positions</th>
                                <th (click)="orderBy('positionStatus')">Position Status</th>
                                <th (click)="orderBy('lastContact')">Last Contact</th>
                                <th (click)="orderBy('rawValue')">Raw Value</th>
                                <th (click)="orderBy('batteryVoltage')">Battery Voltage</th>
                                <th (click)="orderBy('sensorType')">SensorType</th>
                                <th (click)="orderBy('user')">User</th>  
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let device of reportRows">
                                <td><a href="https://www.siteinfo.se/Report/_RedirectToHardware?C_Sensor_Number={{device.serialNumber}}" target="_blank">{{device.serialNumber}}</a></td>
                                <td>{{device.status}}</td>
                                <td>{{device.info}}</td>
                                <td (click)="updateInfo(device.serialNumber, device.info)">
                                    <i class="fas fa-edit"></i>
                                </td>
                                <td>{{device.quality}}</td>
                                <td>{{device.values}}</td>
                                <td>{{device.valueStatus}}</td>
                                <td>{{device.positions}}</td>
                                <td>{{device.positionStatus}}</td>
                                <td>{{device.lastContact | date:'yyyy-MM-dd HH:mm'}}</td>
                                <td>{{device.rawValue}}</td>
                                <td>{{device.batteryVoltage}}</td>
                                <td>{{device.sensorType}}</td>
                                <td>{{device.user}}</td>
                                <td>
                                    <button class="btn btn-danger btn-sm" (click)="removeDevice(device)">Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </spinner>
        </div>
    </div>
</div>