import { Injectable } from '@angular/core';

import { ConfigService } from 'src/app/core/core-config.service';
import { RequestService } from 'src/app/core/services/request.service';
import { Status, StatusReponse } from '../models/client.model';

import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { BehaviorSubject, interval, Observable, Subject, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, subscribeOn, switchMap, takeUntil } from 'rxjs/operators';
import { SystemSummary } from '../models/system-summary.model';
import { versions } from 'process';


@Injectable()
export class MonitoringService {

    private _systemStatus: BehaviorSubject<Status[]> = new BehaviorSubject([]); // App event bus   
    private _systemStatusUpdates$: Observable<Status[]>; // Stream of updates from server
    private _stopPolling = new Subject(); // Stop polling server

    public readonly systemStatus$: Observable<Status[]> = this._systemStatus.asObservable(); // Public stream of all system status
    public readonly systemSummary$: Observable<SystemSummary> = this._systemStatus.asObservable()
        .pipe(map((values) => {
            let summary = new SystemSummary();
            
            console.log("map()", values);

            summary = values.reduce((s, v) => {

                if(v.updatedAt > s.updatedAt) 
                    s.updatedAt = v.updatedAt;

                s.status = this.getHighestStatus(s, v);
                return s;

            }, summary);

            summary.description = this.getSystemStatusDescription(summary.status);

            return summary;

            })); // Public stream of summary

    constructor(
        private requestService: RequestService,
        private configService: ConfigService) {

        console.log('MonitoringService()');

        // Setup the stream of updates
        this._systemStatusUpdates$ = timer(1, 10000).pipe(
            switchMap(() => this.getSystemStatus()),
            retryWhen(errors => errors.pipe(delay(15000))),
            takeUntil(this._stopPolling)
        );

        this._systemStatusUpdates$.subscribe((data) => {
            console.log(data);
            this._systemStatus.next(data);
        });

    }

    compareStatus(a: Status, b: Status): number {
        if (a.status === b.status) {
            return 0;
        }
        if (a.status === "Error") {
            return -1;
        }
        if (b.status === "Error") {
            return 1;
        }
        if (a.status === "Warning") {
            return -1;
        }
        if (b.status === "Warning") {
            return 1;
        }
        if (a.status === "Unkown") {
            return -1;
        }
        if (b.status === "Unkown") {
            return 1;
        }

        return 0;
    }

    getSystemStatusDescription(status: string) : string {
        if(status === 'Error')
            return 'There is a system error.';

        if(status === 'Warning')
            return 'There is a system warning.';

        if(status === 'Unkown')
            return 'Unkown system status.';

        if(status === 'Not Initialized')
            return 'Monitoring has not started.';

        return 'All systems are go';
    }

    getHighestStatus(a: SystemSummary, b: Status) : string {
        if(a.status === 'Error' || b.status === 'Error')
            return 'Error';

        if(a.status === 'Warning' || b.status === 'Warning')
            return 'Warning';

        if(a.status === 'Unkown' || b.status === 'Unkown')
            return 'Unkown';

        return 'Ok';
    }


    getSystemStatus():
        Promise<Status[]> {

        let url =
            `${this.configService.config.apiBasePath}/status`;

        return this.requestService.get<StatusReponse>(url).then(response => {
            return response.results;
        }, error => {
            throw error;
        });
    }

    ngOnDestroy() {
        console.log('MonitoringService - ngOnDestroy()');
        this._stopPolling.next();
        this._stopPolling.complete();
        this._systemStatus.complete();
    }


}
