import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteLink } from 'src/app/core/models/site-link.model';
import { SupportContractSensorReportRow } from 'src/app/support/models/support-contract-sensor-report.model';
import { GotoSiteModal } from './goto-site-modal.component';

@Component({
    selector: 'goto-site-button',
    template: `
        <button (click)="showGotoModal()"
            class="btn btn-sm btn-light">
            <i class="fas fa-external-link-alt"></i> Go
        </button>      
    `
})

export class GotoSiteButtonComponent {

    @Input() public links: SiteLink[];

    constructor(
        private modalService: NgbModal) { }

    ngOnInit(): void {
        console.log('button links', this.links);
    }

    showGotoModal() {
        const modalRef = this.modalService.open(GotoSiteModal,
            { backdrop: 'static', centered: true });
        modalRef.componentInstance.links = this.links;
    }

}
