import { Component, OnInit } from '@angular/core';
import { SiteEasyBatteryStatusReport } from '../models/siteEasy-battery-status-report';
import { SupportService } from '../services/support.service';
import { dynamicSort } from 'src/app/shared/functions/sort';

@Component({
    selector: 'siteEasy-battery-status',
    templateUrl: './siteEasy-battery-status.component.html',
    styleUrls: ['./siteEasy-battery-status.component.scss']
})
export class SiteEasyBatteryStatusComponent implements OnInit {

    public reportRows: SiteEasyBatteryStatusReport[] = [];
    public batteryLowVoltage: SiteEasyBatteryStatusReport[] = [];
    public batteryOutOfOrder: SiteEasyBatteryStatusReport[] = [];
    public allBatteryReportRows: SiteEasyBatteryStatusReport[] = [];
    public searchForCompanyRows: SiteEasyBatteryStatusReport[] = []; 

    public isLoading: boolean;
    public sortOption: string = "all";
    private currentOrder: string = 'objectCurrentDate';
    public searchInput: string;

    constructor(
        public supportService: SupportService) {
    }

    ngOnInit(): void {
        this.getBatteryStaus();  
    }

    async getBatteryStaus()  {

        this.isLoading = true;

        try {
            this.batteryLowVoltage = await this.supportService.getSiteEasyBatteryStatusReport();
            this.batteryOutOfOrder = await this.supportService.getSiteEasyBatteryOutOfOrder();

            this.allBatteryReportRows = this.batteryLowVoltage.concat(this.batteryOutOfOrder);
            this.reportRows = this.allBatteryReportRows;
            this.sortBy();

        } catch (error) {
            console.log(error);
        }

        this.isLoading = false;
    }

    sortBy(): void {

        switch (this.sortOption) {
            case "lowVoltage":
                this.reportRows = this.batteryLowVoltage;
                break;
            case "outOfOrder":
                this.reportRows = this.batteryOutOfOrder;
                break;
            case "all":
                this.reportRows = this.allBatteryReportRows;
                break;
            default:
                break;
        }

        this.searchForCompany();
    }

    searchForCompany() : void {

        if (!this.searchInput) {
            return
        }
        
        var input = this.searchInput.toLowerCase();
        this.searchForCompanyRows = this.reportRows.filter( row => row.companyName.toLowerCase().includes(input));
        this.reportRows = this.searchForCompanyRows; 
    }

    severityLevel(battery: SiteEasyBatteryStatusReport): string {
        switch (battery.severity) {
            case 1:
                return "severityLevelWarning fas fa-battery-half";
            case 2:
                return "severityLevelAlarm fas fa-battery-quarter";
            case 3:
                return "severityLevelCritical fas fa-battery-empty";
            case 4:
                return "outOfOrder fas fa-times";
            default:
                return;
        }
    }

    hasLinks(battery: SiteEasyBatteryStatusReport) {
        if (!battery.siteInfoLinks || battery.siteInfoLinks.length == 0)
            return false;
        return true;
    }

    hasMultipleLinks(sensor: SiteEasyBatteryStatusReport) {
        return sensor.siteInfoLinks.length > 1;
    }

    orderBy(property: string, skipFlip: boolean = false) {

        if (this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if (!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ?
                    property : '-' + property;
            }
        }

        this.reportRows = this.reportRows.sort(dynamicSort(this.currentOrder));
    }

}
