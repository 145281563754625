import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfirmActionModalComponent } from 'src/app/shared/confirm-action/confirm-action-modal.component';
import { ConfirmActionComponent } from 'src/app/shared/confirm-action/confirm-action.component';
import { DisplayErrorModalComponent } from 'src/app/shared/display-error/display-error-modal.component';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { TEK822QualityReportRow } from '../models/tek822-quality-report.model';
import { SupportService } from '../services/support.service';
import { AddTEK822DeviceModalComponent } from './add-device-modal/add-device-modal.component';
import { AddTEK822DeviceSettings } from './add-device-modal/add-device.model';
import { UpdateTek822QualityReportInfo } from '../models/tek822-update-quality-report-info.model';
import { UpdateTek822InfoComponent } from './update-tek822-info-modal/update-tek822-info.component';
import { dynamicSort } from 'src/app/shared/functions/sort';


@Component({
    selector: 'tek822-quality-report',
    templateUrl: './tek822-quality-report.component.html',
})
export class TEK822QualityReportComponent implements OnInit {

    public reportRows: TEK822QualityReportRow[];
    public updatedInfo = new UpdateTek822QualityReportInfo();
    public isLoading: boolean = true;
    private currentOrder: string = 'objectCurrentDate';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorNotification: DisplayErrorService,
        public modalService: NgbModal,
        private supportService: SupportService) {
    }

    ngOnInit(): void {

        this.route.queryParamMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.getTEK822QualityReport()
                    .then(response => {
                        console.log('## TEK822 Quality Report ##', response);
                        this.reportRows = response;
                        this.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                    });
            });

    }
    
    removeDevice(device: TEK822QualityReportRow) {

        let confirmText = `Are you sure you want to remove ${device.serialNumber} from testing?`;

        if (device.quality !== 'A') {
            confirmText = `Are you sure you want to remove ${device.serialNumber} from testing? \n\rIt has quality ${device.quality} but it should be A for delivery.`;
        }

        const modalRef = this.modalService.open(ConfirmActionModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.componentInstance.message = confirmText;
        modalRef.result.then((result) => {
            if (result === true) {

                this.supportService.removeTEK822FromQualityReport(device).then((response) => {
                    console.log(response);
                    this.router.navigate(['/tek822-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
                }, (error) => {
                    console.warn(error);
                });
            }
        });
    }

    setToPowerOff(device: TEK822QualityReportRow) {

        let confirmText = `Are you sure you want set ${device.serialNumber} to power off?`;

        const modalRef = this.modalService.open(ConfirmActionModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.componentInstance.message = confirmText;
        modalRef.result.then((result) => {
            if (result === true) {

                this.supportService.setTEK822ToPowerOff(device).then(() => {
                    this.router.navigate(['/tek822-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
                }, (error) => {
                    console.warn(error);
                });
            }
        });
    }

    resetBatteryStatus(device: TEK822QualityReportRow) {

        let confirmText = `Are you sure you want reset battery status for ${device.serialNumber}?`;

        const modalRef = this.modalService.open(ConfirmActionModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.componentInstance.message = confirmText;
        modalRef.result.then((result) => {

            if (result === true) {

                this.supportService.resetTek822BatteryStatus(device).then(() => {
                    this.router.navigate(['/tek822-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
                }, (error) => {
                    console.warn(error);
                });
            }
        });   
    }

    addDevice() {
        const modalRef = this.modalService.open(AddTEK822DeviceModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.result.then((device: AddTEK822DeviceSettings) => {

            console.log(device);

            this.supportService.addTEK822ToQualityReport(device).then(() => {
                this.router.navigate(['/tek822-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
            }, (response) => {
                this.errorNotification.display(response.error);
            });

        }, () => {});
    }

    orderBy(property: string, skipFlip: boolean = false) {

        if (this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if (!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ?
                    property : '-' + property;
            }
        }
        this.reportRows = this.reportRows.sort(dynamicSort(this.currentOrder));
    }

    updateInfo(serialNumber: string, currentInfo: string) {
        
        const modalRef = this.modalService.open(UpdateTek822InfoComponent,
            { 
                backdrop: 'static', 
                centered: true 
            });

        modalRef.componentInstance.updatedInfo = currentInfo;
        
        modalRef.result.then((info: string) => {

            this.updatedInfo.serialNumber = serialNumber;
            this.updatedInfo.info = info;

            this.supportService.updateTek822QualityReportInfo(this.updatedInfo).then(() => {
                this.router.navigate(['/tek822-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
            }, (response) => {
                this.errorNotification.display(response.error);
            });

        }, () => {});
    }      
}




