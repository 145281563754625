import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-devices',
  templateUrl: './pending-devices.component.html'
})
export class KingspanPendingDevicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
