import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './core/app/app.component';
import { CoreModule } from './core/core.module';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SupportModule } from './support/support.module';


@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        CommonModule,
        CoreModule,
        SupportModule,
        NgbModule
    ],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
