import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute, Data } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";
import { Observer } from "rxjs";

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./breadcrumb.component.html",
})
export class BreadcrumbComponent implements OnInit {
    pageInfo: Data = Object.create(null);
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .pipe(filter((route) => route.outlet === "primary"))
            .pipe(mergeMap((route) => route.data))
            .subscribe((event: Data) => {
                this.titleService.setTitle(event["title"]);
                this.pageInfo = event;

                console.log("pageinfo", this.pageInfo);

                this.pageInfo.breadcrumb = this.pageInfo.breadcrumb ?? (this.pageInfo.title ? true : false);

            });
    }

    // showBreadcrumb(pageInfo : Data) {
    //     if(!pageInfo)
    //         return false;

    //     if(pageInfo.breadcrumb && pageInfo.breadcrumb === false)
    //         return false;

    //     if(pageInfo.breadcrumb)
    //         return true;

    //     if(pageInfo.title)
    //         return true;

    //     return false;
    // }

    ngOnInit() { }
}
