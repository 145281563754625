import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        icon: "mdi mdi-view-dashboard",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "",
        title: "Service & Support",
        icon: "mdi mdi-dots-horizontal",
        class: "nav-small-cap",
        extralink: true,
        submenu: [],
    },
    {
        path: "/system-status",
        title: "System Status",
        icon: "mdi mdi-server",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "/sensors-not-updating",
        title: "Sensor Status",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "/sensor-message-not-updated",
        title: "Sensor Message Updates",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "/siteEasy-battery-status",
        title: "SiteEasy Battery",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },  
    {
        path: "/siteEasy-Ex-battery-status",
        title: "SiteEasy Ex Battery",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "/etm-battery-quality-report",
        title: "ETM Battery Status",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },      
    {
        path: "",
        title: "Test",
        icon: "mdi mdi-dots-horizontal",
        class: "nav-small-cap",
        extralink: true,
        submenu: [],
    },           
    {
        path: "/siteEasy-quality-report",
        title: "Site Easy (ETM)",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [],
    },             
    {
        path: "/tek822-quality-report",
        title: "SiteEasy Ex (TEK822)",
        icon: "mdi mdi-table",
        class: "",
        extralink: false,
        submenu: [], 
    },    
    {
        path: "",
        title: "Other",
        icon: "mdi mdi-dots-horizontal",
        class: "nav-small-cap",
        extralink: true,
        submenu: [],
    },      
    {
        path: "/integrations",
        title: "Integrations",
        icon: "mdi mdi-server",
        class: "",
        extralink: false,
        submenu: [],
    }, 
    {
        path: "/alarm-subscribers",
        title: "Alarm Subscribers",
        icon: "mdi mdi-server",
        class: "",
        extralink: false,
        submenu: [],
    },
    {
        path: "/warehouse-import",
        title: "Warehouse Import",
        icon: "mdi mdi-server",
        class: "",
        extralink: false,
        submenu: [],
    },
];
