<div class="row">

    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                Status
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <dl>
                        <dt class="col-sm-3">Containers</dt>
                        <dd class="col-sm-9">{{ report?.totalContainers || '-'}}</dd>
                        <dt class="col-sm-3">Devices</dt>
                        <dd class="col-sm-9">{{ report?.totalDevices || '-'}}</dd>
                        <dt class="col-sm-3">Unconfigured Devices</dt>
                        <dd class="col-sm-9">{{ report?.totalUnconfiguredDevices || '-'}}</dd>
                    </dl>
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="unconfiguredDevices.length > 0"  class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Unconfigured Devices
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm table-hover">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let deviceId of unconfiguredDevices">
                                    <td>{{deviceId}}</td>
                                    <td><!-- <button (click)="configure(deviceId)" class="btn btn-primary btn-xs">Configure</button> --></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="containersWithoutDevice.length > 0" class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Containers without device
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Container</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let container of containersWithoutDevice">
                                <td>{{container}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        SiteInfo/Packwise integration should create one container per Packwise device. There is at least one container in Packwise without assigned device. If this orphaned container has been created by the integration then remove the container without device in the Packwise portal and reconfigure the Packwise device again.    
                    </div>                    
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="invalidContainers.length > 0" class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Invalid containers
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let row of invalidContainers">
                                <td>{{row.id}}</td>
                                <td>{{row.message}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        The above containers are not correctly configured for the integration to work.    
                    </div>                    
                </spinner>
            </div>
        </div>
    </div>

    <div *ngIf="devicesAssignedMultipleTimes.length > 0" class="col-lg-6">
        <div class="card">
            <div class="card-header">
                Devices assigned multiple times
            </div>
            <div class="card-body">
                <spinner [showSpinner]="isLoading">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Id</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tr *ngFor="let device of devicesAssignedMultipleTimes">
                                <td>{{device.id}}</td>
                                <td>{{device.count}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="alert alert-warning" role="alert">
                        At least one Packwise device has been assigned to multiple containers. The SiteInfo/Packwise integration should only create one container per Packwise device. This could possibly lead to duplicate data beeing sent to SiteInfo. Investigate in the Packwise portal.    
                    </div>
                </spinner>
            </div>
        </div>
    </div>
</div>