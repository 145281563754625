import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { CoreRoutingModule } from './core-routing.module';

import { AppComponent } from './app/app.component';
import { RequestService } from './../core/services/request.service';
import { BaseComponent } from './base/base.component';
import { ProcessLoginComponent } from './auth/process-login.component';
import { LoginComponent } from './auth/login.component';
import { AuthService } from './auth/auth.service';
import { AuthTokenInterceptor } from './auth/auth-token.interceptor';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbComponent } from './base/breadcrumb/breadcrumb.component';
import { NavigationComponent } from './base/header-naviation/navigation.component';
import { SidebarComponent } from './base/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthErrorInterceptor } from './auth/auth-error.interceptor';
import { SessionService } from './services/session.service';
import { UiService } from './services/ui.service';
import { ExternalComponent } from './external/external.component';

export function hostFactory() { return window.location.host; }

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        FormsModule,
        CommonModule,
        CoreRoutingModule,
        SharedModule,
        NgbModule
    ],
    declarations: [
        AppComponent,
        BaseComponent,
        ExternalComponent,
        BreadcrumbComponent,
        SidebarComponent,
        NavigationComponent,
        LoginComponent,
        ProcessLoginComponent
    ],
    providers: [
        { provide: 'HOSTNAME', useFactory: hostFactory },
        RequestService,
        SessionService,
        AuthService,
        UiService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true
        }
    ],
    exports: [
        AppComponent,
        BaseComponent
    ]
})
export class CoreModule { }