<div class="col-xs-12">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary float-right" (click)="goToAddEmail();">Add email</button>
        </div>
    </div>
</div>

<div class="col-xs-12">
    <div class="card">           
        <div class="card-body"> 

            <!-- <spinner [showSpinner]="isLoading"> -->
          
                <div class="table-responsive">                  
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th>E-mail</th>
                                <th></th>
                            </tr>
                        </thead>
                       
                            <tr *ngFor="let subscriber of alarmSubscriber">
                                <td>{{subscriber.email}}</td>
                                <td><button class="btn btn-danger btn-sm" confirm (confirm-action)="removeFromEmailList(subscriber)" confirm-message="Do you want to remove this email?">Remove</button></td>     
                            </tr>
                    </table>
                </div>    
            </div>
        <!-- </spinner>  -->       
    </div>
</div>
