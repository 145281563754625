<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <form #deviceSettings="ngForm" novalidate>
                    <div class="form-group">
                        <label for="companyId">Company</label>
                        <select class="form-control" name="companyId" id="companyId" #companyId="ngModel" [(ngModel)]="registerDeviceForm.connectionId" required>
                                 <option *ngFor="let connection of connection" value="{{connection.id}}">{{connection.companyName}}</option>
                        </select>
                        <div *ngIf="companyId.errors?.required && companyId.touched" class="alert alert-danger">
                            Field is required. 
                        </div> 
                    </div>
                    <div class="form-group">
                        <label for="objectGuid">SiteInfo Object GUID</label>  
                        <input type="text" class="form-control" id="objectGuid" required name="objectGuid" #objectGuid="ngModel" [(ngModel)]="registerDeviceForm.siteInfoGuid">   
                        <div *ngIf="!objectGuid.valid && objectGuid.touched" class="alert alert-danger">
                            Field is required
                        </div>                            
                    </div>
                    <div class="form-group">
                        <label for="sensorId">Signalman Sensor Id</label>
                        <input type="text" class="form-control" id="sensorId" required name="sensorId" #sensorId="ngModel" [(ngModel)]="registerDeviceForm.kingspanSignalmanId" pattern="^[0-9]*$"> 
                        <div *ngIf="sensorId.errors?.required && sensorId.touched" class="alert alert-danger">
                            Field is required. 
                        </div> 
                            <div *ngIf="sensorId.errors?.pattern && !sensorId.errors?.required && sensorId.touched" class="alert alert-danger">
                            Can only contain numbers.
                        </div>       
                    </div>
                    <div class="form-group">
                        <label for="allocationId">Allocation Id</label>
                        <input type="text" class="form-control" id="allocationId" required name="allocationId" #allocationId="ngModel" [(ngModel)]="registerDeviceForm.kingspanAllocationId" ngModel pattern="^[0-9]*$"> 
                        <div *ngIf="allocationId.errors?.required && allocationId.touched" class="alert alert-danger">
                            Field is required. 
                        </div> 
                        <div *ngIf="allocationId.errors?.pattern && !allocationId.errors?.required && allocationId.touched" class="alert alert-danger">
                            Can only contain numbers.
                        </div>   
                    </div>
                    <div class="clearfix mt-5">
                        <button class="btn btn-primary btn float-right" [disabled]="!deviceSettings.valid"
                            (click)="registerDevice()">Add device</button>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>

                
