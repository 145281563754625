import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayErrorModalComponent } from './display-error-modal.component';

@Injectable()
export class DisplayErrorService {

    constructor(private modalService: NgbModal) {
        console.log('DisplayErrorService()');
    }

    display(error: any) {

        const modalRef = this.modalService.open(DisplayErrorModalComponent,
            { backdrop: 'static', centered: true });

        if(error !== null && error !== undefined)
            modalRef.componentInstance.error = error;

    }

}
