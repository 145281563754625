import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseComponent } from '../core/base/base.component';
import { AlarmSubscribersComponent } from './alarm-subscribers/alarm-subscribers.component';
import { SupportDashboardComponent } from './dashboard/dashboard.component';
import { EtmBatteryDetailsComponent } from './etm-battery-quality-report/details/details.component';
import { EtmBatteryQualityReportComponent } from './etm-battery-quality-report/etm-battery-quality-report.component';
import { BintelConfigureDeviceComponent } from './integrations/bintel/configure-device/configure-device.component';
import { BintelIntegrationOverviewComponent } from './integrations/bintel/overview/overview.component';
import { KingspanDeviceListComponent } from './integrations/kingspan/device-list/device-list.component';
import { KingspanIntegrationOverviewComponent } from './integrations/kingspan/overview/overview.component';
import { KingspanPendingDevicesComponent } from './integrations/kingspan/pending-devices/pending-devices.component';
import { RegisterKingspanDeviceComponent } from './integrations/kingspan/register-device/register-device.component';
import { IntegrationsOverviewComponent } from './integrations/overview.component';
import { PackwiseIntegrationOverviewComponent } from './integrations/packwise/overview/overview.component';
import { OutdatedSensorsComponent } from './sensor-list/outdated/outdated-sensors.component';
import { SiteEasyQualityReportComponent } from './siteEasy-quality-report/siteEasy-quality-report.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { TEK822QualityReportComponent } from './tek822-quality-report/tek822-quality-report.component';
import { AddAlarmSubscriberComponent } from './alarm-subscribers/add-alarm-subscriber/add-alarm-subscriber.component';
import { SiteEasyBatteryStatusComponent } from './siteEasy-battery-status/siteEasy-battery-status.component';
import { PackwiseRegisterCarrierNumberComponent } from './integrations/packwise/register-carrier-number/register-carrier-number.component';
import { PackwiseRemoveCarrierNumberComponent } from './integrations/packwise/remove-carrier-number/remove-carrier-number.component';
import { PackwiseConfigureDeviceComponent } from './integrations/packwise/configure-device/configure-device.component';
import { SiteEasyExBatteryStatusComponent } from './siteEasy-Ex-battery-status/siteEasy-Ex-battery-status.component';
import { SensorMessageNotUpdatedComponent } from './sensor-message-not-updated/sensor-message-not-updated.component';
import { WarehouseImportComponent } from './warehouse-import/warehouse-import.component';
import { ExternalComponent } from '../core/external/external.component';

@NgModule({
    declarations: [
    ],
    imports: [
        RouterModule.forRoot([
            {
                path: 'ext', component: ExternalComponent, children: [
                    {
                        path: 'tek822-quality-report',
                        component: TEK822QualityReportComponent,
                        data: {
                            title: 'SiteEasy Ex Test',
                            breadcrumb: true
                        }
                        //canActivate: [Guard]
                    },
                ]
            },
            {
                path: '', component: BaseComponent, children: [
                    {
                        path: 'dashboard',
                        component: SupportDashboardComponent,
                        data: {
                            title: 'Support Dashboard',
                            breadcrumb: false
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'system-status',
                        component: SystemStatusComponent,
                        data: {
                            title: 'System Status'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'sensors-not-updating',
                        component: OutdatedSensorsComponent,
                        data: {
                            title: 'Sensors - Not updating'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'sensor-message-not-updated',
                        component: SensorMessageNotUpdatedComponent,
                        data: {
                            title: 'Sensors - Message not updated'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'siteEasy-quality-report',
                        component: SiteEasyQualityReportComponent,
                        data: {
                            title: 'SiteEasy Test'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'tek822-quality-report',
                        component: TEK822QualityReportComponent,
                        data: {
                            title: 'SiteEasy Ex Test'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'etm-battery-quality-report',
                        component: EtmBatteryQualityReportComponent,
                        data: {
                            title: 'ETM Battery Quality Report'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'etm-battery-quality-report/:icc',
                        component: EtmBatteryDetailsComponent,
                        data: {
                            title: 'ETM Battery Quality Report - Detail'
                        }
                        //canActivate: [Guard]
                    },
                    {
                        path: 'integrations',
                        component: IntegrationsOverviewComponent,
                        data: {
                            title: 'Integrations'
                        }
                    },
                    {
                        path: 'integrations/bintel/status',
                        component: BintelIntegrationOverviewComponent,
                        data: {
                            title: 'Bintel Integration - Status'
                        }
                    },
                    {
                        path: 'integrations/bintel/configure-device',
                        component: BintelConfigureDeviceComponent,
                        data: {
                            title: 'Bintel Integration - Configure Device'
                        }
                    },
                    {
                        path: 'integrations/packwise/status',
                        component: PackwiseIntegrationOverviewComponent,
                        data: {
                            title: 'Packwise Integration - Status'
                        }
                    },
                    {
                        path: 'integrations/packwise/register-carrier-number',
                        component: PackwiseRegisterCarrierNumberComponent,
                        data: {
                            title: 'Packwise Integration - Register Carrier Number'
                        }
                    },
                    {
                        path: 'integrations/packwise/remove-carrier-number',
                        component: PackwiseRemoveCarrierNumberComponent,
                        data: {
                            title: 'Packwise Integration - Delete Carrier Number'
                        }
                    },
                    {
                        path: 'integrations/packwise/configure-device',
                        component: PackwiseConfigureDeviceComponent,
                        data: {
                            title: 'Packwise Integration - Configure Device'
                        }
                    },
                    {
                        path: 'integrations/kingspan/register-device',
                        component: RegisterKingspanDeviceComponent,
                        data: {
                            title: 'Kingspan Integration - Register Device'
                        }
                    },
                    {
                        path: 'integrations/kingspan/status',
                        component: KingspanIntegrationOverviewComponent,
                        data: {
                            title: 'Kingspan Integration - Status'
                        }
                    },
                    {
                        path: 'integrations/kingspan/device-list',
                        component: KingspanDeviceListComponent,
                        data: {
                            title: 'Kingspan Integration - Device List'
                        }
                    },
                    {
                        path: 'integrations/kingspan/pending-devices',
                        component: KingspanPendingDevicesComponent,
                        data: {
                            title: 'Kingspan Integration - Pending Devices'
                        }
                    },
                    {
                        path: 'alarm-subscribers',
                        component: AlarmSubscribersComponent,
                        data: {
                            title: 'Alarm Subscribers'
                        }
                    },
                    {
                        path: 'add-alarm-subscribers',
                        component: AddAlarmSubscriberComponent,
                        data: {
                            title: 'Add new subscriber'
                        }
                    },
                    {
                        path: 'siteEasy-battery-status',
                        component: SiteEasyBatteryStatusComponent,
                        data: {
                            title: 'SiteEasy - Battery Status'
                        }
                    },
                    {
                        path: 'siteEasy-Ex-battery-status',
                        component: SiteEasyExBatteryStatusComponent,
                        data: {
                            title: 'SiteEasy Ex - Battery Status'
                        }
                    },
                    {
                        path: 'warehouse-import',
                        component: WarehouseImportComponent,
                        data: {
                            title: 'Warehouse Import'
                        }
                    }


                ]
            },
        ])
    ]
})
export class SupportRoutingModule {
}



