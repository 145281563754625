<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                Status
            </div>
            <div class="card-body">
                <!-- <spinner [showSpinner]="isLoading">-->                
                    <dl>
                        <dt class="col-sm-3">Connections</dt>
                        <dd class="col-sm-9">{{ report?.connectionsCount || '-'}}</dd>
                        <dt class="col-sm-3">Devices</dt>
                        <dd class="col-sm-9">{{ report?.registeredDevicesCount || '-'}}</dd>
                    </dl>
                <!--</spinner> -->
            </div>
        </div>
    </div>
</div>
