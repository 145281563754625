import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/support/services/support.service';
import { PackwiseDeleteCarrierNumber } from 'src/app/support/models/packwise-integration-carrier-number.model';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-remove-carrier-number',
	templateUrl: './remove-carrier-number.component.html'
})
export class PackwiseRemoveCarrierNumberComponent implements OnInit {

	public carrierNumberDelete = new PackwiseDeleteCarrierNumber();

	constructor(
		public supportService: SupportService,
		public router: Router,
		private errorService: DisplayErrorService
	) { }

	ngOnInit(): void {
	}

	deleteCarrierNumber(): void {

		this.supportService. deletePackwiseCarrierNumber(this.carrierNumberDelete)
			.then(response => {
				console.log('## Packwise Delete Carrier Number ##', response);

				if(!response.successful) {
					alert(response.error);
					this.router.navigate(['/integrations/packwise/remove-carrier-number']);
				}	
				else {
					alert("Carrier number deleted");
            		this.router.navigate(['/integrations/packwise/status']);
				}				
			}).catch(error => {
				this.errorService.display(error);
			});
	}

	goBack(): void {
		this.router.navigateByUrl('integrations');
	}

}
