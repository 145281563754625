import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    // styleUrls: ['./base.component.scss']
})
export class BaseComponent {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {

    }

    public innerWidth: number = 0;
    public defaultSidebar: string = '';
    public showMobileMenu = false;
    public expandLogo = false;
    public sidebartype = 'full';

    Logo() {
        this.expandLogo = !this.expandLogo;
    }

    ngOnInit() {
        if (this.router.url === '/') {
            this.router.navigate(['/dashboard']);
        }
        this.defaultSidebar = this.sidebartype;
        this.handleSidebar();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.handleSidebar();
    }

    handleSidebar() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth < 1170) {
            this.sidebartype = 'mini-sidebar';
        } else {
            this.sidebartype = this.defaultSidebar;
        }
    }

    toggleSidebarType() {
        switch (this.sidebartype) {
            case 'full':
                this.sidebartype = 'mini-sidebar';
                break;

            case 'mini-sidebar':
                this.sidebartype = 'full';
                break;

            default:
        }
    }

}
