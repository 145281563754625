export class PackwiseUnusedCarrierNumber{
    carrierNumber: string;
}

export class PackwiseUnusedCarrierNumberList {
    carrierNumbers: PackwiseUnusedCarrierNumber[];
}

export class PackwiseRegisterCarrierNumber {
    carrierNumber: string;
    packwiseId: string;
}

export class PackwiseDeleteCarrierNumber {
    carrierNumber: string;
    deleteText: string; 
}