<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" dir="ltr" data-theme="light"
    data-layout="vertical" [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute"
    data-header-position="fixed" data-boxed-layout="full">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
    <header class="topbar" data-navbarbg="skin6">
        <nav class="d-flex top-navbar navbar-expand-md navbar-light">
            <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" data-logobg="skin6">
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <a (click)="showMobileMenu = !showMobileMenu"
                    class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
                    <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
                </a>
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <a class="navbar-brand" href="/">
                    <b class="logo-icon">
                        <i class="mdi mdi-alarm"></i>
                        <!-- Light Logo icon -->
                    </b>
                    <span class="logo-text">
                        MCD Support Portal
                        <!-- <img src="assets/images/logo-text.png" class="dark-logo" alt="homepage" /> -->
                    </span>
                </a>
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            
            <div class="navbar-collapse" id="navbarSupportedContent" data-navbarbg="skin6">
                <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
            </div>
        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" data-sidebarbg="skin1" (mouseover)="Logo()" (mouseout)="Logo()">
        <!-- Sidebar scroll
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            
        </div>
        End Sidebar scroll-->
        <app-sidebar></app-sidebar>
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <app-breadcrumb></app-breadcrumb>
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>