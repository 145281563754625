import { Component, Injectable, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EtmBatteryQualityReportRow } from '../../models/etm-battery-quality-report.model';
import { SupportService } from '../../services/support.service';

@Component({
    selector: 'etm-battery-details',
    templateUrl: './details.component.html'
})

export class EtmBatteryDetailsComponent {

    public device: EtmBatteryQualityReportRow;
    public isLoading = true;
    public lastDate: Date = new Date();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService) {
            
         }


    ngOnInit(): void {
        console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.getEtmBatteryQualityReportRow(params.get('icc'))
                    .then(response => {
                        console.log('## ETM Battery Quality Report Row ##', response);
                        this.device = response;
                        this.lastDate = this.device.lastDate;
                        this.isLoading = false;
                    }).catch(error => {
                    });
            });

    }

    back() {
        this.router.navigate(['etm-battery-quality-report'], { queryParams: { useCache: 'true' }});
    }

}
