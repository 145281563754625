import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {

    private data : Object = {};

    constructor() {
        console.log('SessionService()');
    }

    add(key: string, data: any) {
        this.data[key] = data;
    }

    get(key: string) : any {
        if(!this.data.hasOwnProperty(key))
            return null;

        return this.data[key];
    }

}
