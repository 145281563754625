import { Component, OnInit } from '@angular/core';
import { Company, Equipment, InvoiceResponsible, ValidateCarrierRequest, WarehouseImportSetting, SerialNumberListItem } from '../models/warehouse-import.model';
import { SerialNumberFormSetting } from '../models/warehouse-import.model';
import { SupportService } from '../services/support.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplaySuccessModalComponent } from 'src/app/shared/display-success/display-success-modal.component';

@Component({
	selector: 'app-warehouse-import',
	templateUrl: './warehouse-import.component.html',
	styleUrls: ['./warehouse-import.component.css']
})
export class WarehouseImportComponent implements OnInit {

	public serialNumberFormSettings = new SerialNumberFormSetting();
	public companies: Company[] = [];
	public invoiceResponsibles: InvoiceResponsible[] = [];
	public equipments: Equipment[] = [];
	public serialNumberList: SerialNumberListItem[] = [];
	public carrierValidation = new ValidateCarrierRequest();
	public warehouseImportSettings = new WarehouseImportSetting();

	public isLoading: boolean = false; 
	public rentalContractIsEnabled: boolean = false;
	public showErrorMessage: boolean = false;
	public importButtonEnable: boolean = false;

	public validationError: string = "";
	public successMessage: string = "";
	
	constructor(
		public supportService: SupportService,
		public modalService: NgbModal,) { }

	ngOnInit(): void {

		this.getCompanies();
		this.getEquiptments();
	}

	getCompanies() {

		this.supportService.getWarehouseImportCompanies()
        .then(response => {    
			this.companies = response;                
        }).catch(error => {
            console.log(error);
        });
	}

	getEquiptments() {
		this.supportService.getWarehouseImportEquipment()
        .then(response => {    
			this.equipments = response;               
        }).catch(error => {
            console.log(error);
        });
	}

	getInvoiceResponsible() {

		var companyId = parseInt(this.serialNumberFormSettings.companyId);

		this.supportService.getWarehouseImportInvoiceResponsibles(companyId)
        .then(response => {  
			this.invoiceResponsibles = response;
        }).catch(error => {
            console.log(error);
        });
	}

	setHasRentalContract() {
		var contactId = this.invoiceResponsibles.find(contact => parseInt(this.serialNumberFormSettings.contactId) === contact.contactId);

		if(contactId.hasRentalContract === true)
			this.rentalContractIsEnabled = true;
		else {
			this.rentalContractIsEnabled = false;
			this.serialNumberFormSettings.hasRentalContract = false;
		}							
	}

	addSerialNumber() {

		if(this.serialNumberFormSettings.singleSerialNumber === "" || !this.serialNumberFormSettings.singleSerialNumber)
			return;

		var highestId = 0;
		this.serialNumberList.forEach(id => {
			if(id.serialNumberId > highestId) {
				highestId = id.serialNumberId;
			}
		});

		var serialNumber = new SerialNumberListItem();
		serialNumber.serialNumberId = highestId + 1;
		serialNumber.serialNumber = this.serialNumberFormSettings.singleSerialNumber;		
		serialNumber.isValidationOk = null;
		serialNumber.validationError = "";
		this.serialNumberList.push(serialNumber);

		this.serialNumberFormSettings.singleSerialNumber = "";
		this.importButtonEnable = false;
	}

	fileButtonEvent() {
		document.getElementById("fileUpload").click();
	}

	uploadFile(event) {

		this.serialNumberList = [];
		var csvNumbers = [];
		var file = <File>event.target.files[0];

		var highestId = 0;
		this.serialNumberList.forEach(id => {
			if(id.serialNumberId > highestId) {
				highestId = id.serialNumberId;
			}
		});
		var idNumber = highestId;
	
		var readFile = new Promise((resolve, reject) => {
			let fileReader = new FileReader();
			fileReader.onload = () => {
				resolve(fileReader.result);			
			};
			fileReader.onerror = reject;			
			fileReader.readAsText(file);
		});
		readFile.then(result => {
			csvNumbers = (result as string).split('\n');		
			csvNumbers.forEach(csvNumber => {
				if(csvNumber === "" || csvNumber === "\r" ) {
					return
				}		
				csvNumber = csvNumber.replace(/\r?\n|\r/g, "");

				var number = new SerialNumberListItem();
				number.serialNumberId = idNumber;
				number.serialNumber = csvNumber;
				number.isValidationOk = null;
				number.validationError = "";

				this.serialNumberList.push(number);
				idNumber++;		
			})
		})
		this.showErrorMessage = false;
		this.importButtonEnable = false;
		event.target.value = "";
	}

	checkValidationStatus(validationStatus: boolean) {
        switch (validationStatus) {
            case true:
                return "validationOk fas fa-check-circle";
            case false:
                return "validationNotOk fas fa-exclamation-triangle";
            case null:
                return "";
            default:
                return;
        }
    }

	validateCarrier() {
		var serialNumbers = [];
		this.serialNumberList.forEach(number => {
			serialNumbers.push(number.serialNumber);
		})
		this.carrierValidation = {
			carrierTypeId: parseInt(this.serialNumberFormSettings.carrierTypeId),
			serialNumbers: serialNumbers
		}	
		this.isLoading = true;

		this.supportService.validateCarrier(this.carrierValidation)
        .then(response => {   
			this.serialNumberList = response;
			this.isLoading = false;

			for(var i = 0; i < this.serialNumberList.length; i++ ) {
				if(this.serialNumberList[i].isValidationOk == false || this.serialNumberList[i].isValidationOk == null) {
					this.showErrorMessage = true;
					this.importButtonEnable = false; 
					break;
				}
				else {
					this.showErrorMessage = false;
					this.importButtonEnable = true; 
				}					
			}
		}) 
		.catch(error => {
            console.log(error);
        });
	}

	removeSerialNumber(serialNumberId: number) {

		this.serialNumberList = this.serialNumberList.filter(number => number.serialNumberId !==  serialNumberId);

		if(this.serialNumberList.length < 1) {
			this.showErrorMessage = false;
			this.importButtonEnable = false;
			return;
		}
		for(var i = 0; i < this.serialNumberList.length; i++ ) {
			if(this.serialNumberList[i].isValidationOk == false) {
				this.showErrorMessage = true;
				this.importButtonEnable = false;
				break;
			}
			else {
				this.showErrorMessage = false;
				this.importButtonEnable = true;
			}
		}
	}

	submit() {

		this.validationError = "";

		var serialNumbers = [];
		this.serialNumberList.forEach(number => {
			serialNumbers.push(number.serialNumber);
		})
		this.warehouseImportSettings = {
			companyId: parseInt(this.serialNumberFormSettings.companyId),
			invoiceResponsibleId: parseInt(this.serialNumberFormSettings.contactId),
			carrierTypeId: parseInt(this.serialNumberFormSettings.carrierTypeId),
			hasRentalContract: this.serialNumberFormSettings.hasRentalContract,
			serialNumbers: serialNumbers
		}
		this.supportService.setWarehouseImport(this.warehouseImportSettings)
			.then(response => {   
				const modalRef = this.modalService.open(DisplaySuccessModalComponent,
					{ backdrop: 'static', centered: true });
				modalRef.componentInstance.message = "All serial numbers are imported!"	
				modalRef.result.then((result) => {
					window.scrollTo(0,0);
					location.reload();								
				});
			}).catch(error => {
				console.log(error);
				this.validationError = error.detail;	
			});
		}
}
