
    <div class="col-xs-12">
        <div class="card">
            <div class="card-body">
                <button class="btn btn-primary float-right" (click)="goToAddDevice();">Add device</button>
            </div>
        </div>
    </div>

    <div class="col-xs-12">
        <div class="card">
                 
            <div class="card-body"> 

            
                <!-- <spinner [showSpinner]="isLoading"> -->
              
                    <div class="table-responsive">
                        
                        <table class="table table-sm">
                            <thead class="thead-light">
                                <tr>
                                    <th>Site Info Guid</th>
                                    <th>Allocation Id</th>
                                    <th>Signalman Id</th>
                                    <th>Connection Id</th>
                                    <th>Id</th>       
                                    <th>Remove</th>
                                </tr>
                            </thead>
                                <tr *ngFor="let device of devices">
                                    <td>{{device.siteInfoGuid}}</td>
                                    <td>{{device.kingspanAllocationId}}</td>
                                    <td>{{device.kingspanSignalmanId}}</td>
                                    <td>{{device.connectionId}}</td>
                                    <td>{{device.id}}</td>
                                    <td><button class="btn btn-danger btn-sm" confirm (confirm-action)="removeDevice(device)" confirm-message="Do you want to remove this device?">Remove</button></td>     
                                </tr>
                        </table>
                    </div>    
                </div>
            <!-- </spinner>  -->       
        </div>
    </div>

