import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, retry, retryWhen, switchMap, takeUntil } from 'rxjs/operators';
import { Status } from '../models/client.model';
import { MonitoringService } from '../services/monitoring.service';

@Component({
    selector: 'system-status',
    templateUrl: './system-status.component.html',
})
export class SystemStatusComponent implements OnInit {

    public showAll: boolean = true;
    public systemStatusProd$: Observable<Status[]>;
    public systemStatusTest$: Observable<Status[]>;
    public systemStatusOther$: Observable<Status[]>;

    constructor(
        private route: ActivatedRoute,
        private monitoringService: MonitoringService) {

    }

    ngOnInit(): void {

        this.systemStatusProd$ = this.monitoringService.systemStatus$.pipe(
            map((x) => {
                x.sort(this.monitoringService.compareStatus);
                x = x.filter((i) => i.environment === "Prod")
                return x;
        }));

        this.systemStatusTest$ = this.monitoringService.systemStatus$.pipe(
            map((x) => {
                x.sort(this.monitoringService.compareStatus);
                x = x.filter((i) => i.environment === "Test")
                return x;
        })); 

        this.systemStatusOther$ = this.monitoringService.systemStatus$.pipe(
            map((x) => {
                x.sort(this.monitoringService.compareStatus);
                x = x.filter((i) => (i.environment !== "Test" && i.environment !== "Prod"))
                return x;
        })); 



        // // getSystemStatus returns a promise!
        // this.systemStatus$ = timer(1, 5000).pipe(
        //     switchMap(() => this.monitoringService.getSystemStatus()),
        //     map((x) => {
        //         x.sort(compareFn);
        //         console.log(x);
        //         return x;
        //     }),
        //     retryWhen(errors => errors.pipe(delay(10000))),
        //     takeUntil(this.stopPolling)
        // );

    }

    showStatus(status: Status) : boolean {
        return this.showAll || status.status !== 'Ok'; 
    }

    statusBackground(status: Status) : string {

        switch(status.status) {
            case 'Ok': 
                return 'bg-success';
            case 'Warning': 
                return 'bg-warning';
            case 'Error':
                return  'bg-danger';
            default: 
                return 'bg-secondary';
        }

    }

    ngOnDestroy() {
     //   this.systemStatus$.unsubscribe();
    }

    test() {
        alert('Test from parent');
    }
}



