<div class="col-xs-12">
    <div class="card no-border">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead class="thead-light">
                        <tr>
                            <th></th>
                            <th (click)="orderBy('companyName')" class="col-max-200">Company</th>
                            <th (click)="orderBy('siteName')" class="col-max-200">Site</th>
                            <th (click)="orderBy('siteName')" class="col-max-80"></th>
                            <th (click)="orderBy('objectCurrentDate')" class="col-max-200">Updated At</th>
                            <th></th>
                            <th (click)="orderBy('siteName')">Latest Message</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let sensor of supportSensors">
                        <ng-container *ngIf="checkIfDateHasPassed(sensor.dateHideUntil)">
                            <td><i [ngClass]="showDateHasPassedAction(sensor.dateHideUntil)"></i></td>
                            <td class="col-max-200" title="{{sensor.companyName}}">{{sensor.companyName}} </td>
                            <td class="col-max-200" title="{{sensor.siteName}}">
                                <ng-container *ngIf="hasMultipleLinks(sensor)">
                                    {{sensor.siteName}}
                                </ng-container>
                                <ng-conatiner *ngIf="hasLinks(sensor) && !hasMultipleLinks(sensor)">
                                    <a href="{{ sensor.siteInfoLinks[0].url}}" title="{{sensor.siteName}}"
                                        target="_blank">{{sensor.siteName}}</a>
                                </ng-conatiner>
                            </td>
                            <td class="col-max-80">
                                <goto-site-button *ngIf="hasLinks(sensor) && hasMultipleLinks(sensor)"
                                    [links]="sensor.siteInfoLinks"></goto-site-button>
                            </td>
                            <td class="col-max-200">{{sensor.objectCurrentDate  | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td><i class="fas" [ngClass]="sensorIcon(sensor)"></i></td>
                            <td>{{sensor.objectMessageText }}</td>
                        </ng-container>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>