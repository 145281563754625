import { Component, Injectable, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackwiseIntegrationStatusReport, PackwiseInvalidContainerReportRow, PackwiseMultipleDeviceReportRow } from 'src/app/support/models/packwise-integration-status-report.model';
import { SupportService } from 'src/app/support/services/support.service';

@Component({
    selector: 'packwise-integration-overview',
    templateUrl: './overview.component.html'
})

export class PackwiseIntegrationOverviewComponent {

    public report: PackwiseIntegrationStatusReport;
    public devicesAssignedMultipleTimes: PackwiseMultipleDeviceReportRow[] = [];
    public containersWithoutDevice: string[] = [];
    public unconfiguredDevices: string[] = [];
    public invalidContainers: PackwiseInvalidContainerReportRow[] = [];

    public isLoading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService) {
            
         }


    ngOnInit(): void {
        console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.packwiseIntegrationStatusReport()
                    .then(response => {
                        console.log('## Packwise Integration Status Report ##', response);
                        this.report = response;
                        this.containersWithoutDevice = this.report.containersWithoutDevice;
                        this.devicesAssignedMultipleTimes = this.report.devicesAssignedMultipleTimes;
                        this.unconfiguredDevices = this.report.unconfiguredDevices;
                        this.invalidContainers = this.report.invalidContainers;
                        this.isLoading = false;
                    }).catch(error => {
                    });

            });

    }

    // configure(deviceId: string) {
    //     this.router.navigate(['/integrations/bintel/configure-device'], { queryParams: { carrierNumber: deviceId } });
    // }


}
