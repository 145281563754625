import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-hide-sensor-modal',
	templateUrl: './hide-sensor-modal.component.html',
})
export class HideSensorModalComponent implements OnInit {

	public selectedDate: string; 

	constructor(
		public modal: NgbActiveModal
	) { }

	ngOnInit(): void {
	}

	save() {
		this.modal.close(this.selectedDate);
	}

	cancel() {
		this.modal.dismiss();
	}

}
