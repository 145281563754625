import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';


@Component({
    selector: 'process-login',
    templateUrl: './process-login.component.html'
})
export class ProcessLoginComponent {

    private paramsSub: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {


    }

    ngOnInit(): void {
        console.log('ngOnInit()');

        this.paramsSub = this.route.fragment.subscribe(fragmentString => {

            // Parses a fragment string e.g. access_token=abc&token_type=Bearer&expires_in=3600&scope=abc&state=abc

            let fragments: { [key: string]: string } =
                fragmentString.split('&').map((fragment) => {
                    if (fragment.indexOf('=') < 0)
                        return null;

                    let t = fragment.split('=');
                    let key = t[0];
                    let value = t[1];

                    let kvp = {};
                    kvp[key] = value;

                    return kvp;
                }).reduce((kvps: any, kvp: any) => {
                    if (!kvp)
                        return kvps;
                    Object.assign(kvps, kvp);
                    return kvps;
                }, {});

            let idToken = fragments['id_token'];
            let accessToken = fragments['access_token'];
            let state = fragments['state'];

            this.handleAuthCallback(idToken, accessToken, state);
        });


    }

    handleAuthCallback(idToken: string, accessToken: string, state: string) {
        console.log('handleAuthCallback()');

        this.authService.handleCallback(idToken, accessToken, state).then((result) => {

            if(result.returnUrl) {
                this.router.navigate([result.returnUrl]);
            }
            else {
                this.router.navigate(['dashboard']);
            }
  
        }, () => {
            alert('Login failed. Try again.');
        });
    }

}
