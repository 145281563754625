import { Injectable } from '@angular/core';

import { ConfigService } from 'src/app/core/core-config.service';
import { RequestService } from 'src/app/core/services/request.service';
import { Status, StatusReponse } from '../models/client.model';

import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { BehaviorSubject, interval, Observable, Subject, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, subscribeOn, switchMap, takeUntil } from 'rxjs/operators';
import { SystemSummary } from '../models/system-summary.model';
import { versions } from 'process';
import { SupportContractSensorReportRow } from '../models/support-contract-sensor-report.model';
import { ObjectStatisticReport } from '../models/object-statistic-report.model';
import { SiteEasyQualityReportRow } from '../models/siteEasy-quality-report.model';
import { EtmBatteryQualityReportRow } from '../models/etm-battery-quality-report.model';
import { Session } from 'protractor';
import { SessionService } from 'src/app/core/services/session.service';
import { BintelIntegrationStatusReport } from '../models/bintel-integration-status-report.model';
import { ConfigureDeviceSettings } from '../integrations/bintel/configure-device/configure-device.model';
import { TEK822QualityReportRow } from '../models/tek822-quality-report.model';
import { PackwiseIntegrationStatusReport } from '../models/packwise-integration-status-report.model';
import { KingspanRegistredDeviceList, KingspanRegistredDevices, KingspanRegisterDeviceConnection, KingspanRegisterDeviceConnectionList } from '../models/kingspan-integration-registred-devices.model';
import { KingspanIntegrationStatusReport } from '../models/kingspan-integration-status-report.model';
import { AlarmSubscriberList, AlarmSubscriber } from '../models/alarm-subscriber.model';
import { SiteEasyBatteryStatusReport} from '../models/siteEasy-battery-status-report';
import { PackwiseUnusedCarrierNumber, PackwiseUnusedCarrierNumberList, PackwiseRegisterCarrierNumber, PackwiseDeleteCarrierNumber } from '../models/packwise-integration-carrier-number.model';
import { PackwiseConfigureDevice } from '../models/packwise-integration-configure-device-model';
import { AddTEK822DeviceSettings } from '../tek822-quality-report/add-device-modal/add-device.model';
import { UpdateTek822QualityReportInfo } from '../models/tek822-update-quality-report-info.model';
import { SiteEasyExBatteryStatusReport } from '../models/siteEasy-Ex-battery-status-report.model';
import { AddDeviceSettings } from '../siteEasy-quality-report/add-device-modal/add-device.model';
import { SensorMessageNotUpdatedReport } from '../models/sensor-message-not-updated-report';
import { UpdateSiteEasyQualityReportInfo } from '../models/siteEasy-update-quality-report-info.model';
import { Company, InvoiceResponsible, Equipment, ValidateCarrierRequest, ValidateCarrierResponse, WarehouseImportSetting } from '../models/warehouse-import.model';
import { SensorStatusListFilter } from '../models/sensor-status-list-filter.model';
import { AuthService } from 'src/app/core/auth/auth.service';

@Injectable()
export class SupportService {

    constructor(
        private authService: AuthService,
        private requestService: RequestService,
        private sessionService: SessionService,
        private configService: ConfigService) {

        console.log('SupportService()');

    }

    getSupportContractSensorReport():
        Promise<SupportContractSensorReportRow[]> {

        let url = `${this.configService.config.mcdApiBasePath}/support/supportContractSensorReport`;

        return this.requestService.get<SupportContractSensorReportRow[]>(url).then(response => {

            response.forEach((x) => {
                x.siteInfoLinks = x.siteInfoLinks.map(
                    (link) => {
                        link.url = this.configService.config.siteInfoBasePath + link.url;
                        return link;
                    })
            });

            return response;
        }, error => {
            throw error;
        });
    }

    getSupportNoContractSensorReport():
        Promise<SupportContractSensorReportRow[]> {

        let url = `${this.configService.config.mcdApiBasePath}/support/supportNoContractSensorReport`;

        return this.requestService.get<SupportContractSensorReportRow[]>(url).then(response => {

            response.forEach((x) => {
                x.siteInfoLinks = x.siteInfoLinks.map(
                    (link) => {
                        link.url = this.configService.config.siteInfoBasePath + link.url;
                        return link;
                    })
            });

            return response;
        }, error => {
            throw error;
        });
    }

    setSensorStatusListFilter(listFilter: SensorStatusListFilter):
        Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/support/setSensorStatusListFilter`;

        return this.requestService.post<any>(url, listFilter).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    deleteSensorStatusListFilter(siteId: number): 
        Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/support/deleteSensorStatusListFilter`;

        let data: any = {
            siteId: siteId
        };
        
        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });      
    }

    getObjectStatisticReport():
        Promise<ObjectStatisticReport> {

        let url =
            `${this.configService.config.mcdApiBasePath}/support/objectStatisticReport`;

        return this.requestService.get<ObjectStatisticReport>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    getSensorMessageNotUpdatedReport():
        Promise<SensorMessageNotUpdatedReport[]> {

        let url =
            `${this.configService.config.mcdApiBasePath}/support/sensorStatusNotOkReport`;

            return this.requestService.get<SensorMessageNotUpdatedReport[]>(url).then(response => {    
            response.forEach((x) => {
                x.siteInfoLinks = x.siteInfoLinks.map(
                    (link) => {
                        link.url = this.configService.config.siteInfoBasePath + link.url;
                        return link;
                    })
            });

            return response;
        }, error => {
            throw error;
        });
    }

    getEtmBatteryQualityReportRow(icc: string): Promise<EtmBatteryQualityReportRow> {

        let report: EtmBatteryQualityReportRow[] = this.sessionService.get('etmBatteryQualityReport');

        if (!report) {
            return this.getEtmBatteryQualityReport(false).then(() => {
                return this.getEtmBatteryQualityReportRow(icc);
            });
        }

        let rows = report.filter(x => x.icc === icc);
        if (rows.length > 0) {
            return Promise.resolve(rows[0]);
        }
        else {
            return Promise.resolve(null);
        }

    }

    getEtmBatteryQualityReport(useCache: boolean):
        Promise<EtmBatteryQualityReportRow[]> {

        if (useCache) {
            let report: EtmBatteryQualityReportRow[] =
                this.sessionService.get('etmBatteryQualityReport');
            if (report && report.length > 0) {
                return Promise.resolve(report);
            }
        }

        let url =
            `${this.configService.config.mcdApiBasePath}/portal/etmBatteryQualityReport`;

        return this.requestService.get<EtmBatteryQualityReportRow[]>(url).then(response => {

            response.forEach((x) => {
                x.siteInfoLinks = x.siteInfoLinks.map(
                    (link) => {
                        link.url = this.configService.config.siteInfoBasePath + link.url;
                        return link;
                    })
            });

            // Save for later as the endpoint does not support search
            this.sessionService.add('etmBatteryQualityReport', response);

            return response;
        }, error => {
            throw error;
        });
    }

    clearETMBatteryBuffers(): Promise<any> {
        let url =
            `${this.configService.config.mcdApiBasePath}/portal/clearETMBatteryBuffer`;

        return this.requestService.post<SiteEasyQualityReportRow[]>(url, {}).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    getSiteEasyQualityReport():
        Promise<SiteEasyQualityReportRow[]> {

        let url =
            `${this.configService.config.mcdApiBasePath}/portal/siteEasyQualityReport`;

        return this.requestService.get<SiteEasyQualityReportRow[]>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    addSiteEasyToQualityReport(device: AddDeviceSettings):
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/portal/addSiteEasyToQualityReport`;

        return this.requestService.post<any>(url, device).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    removeSiteEasyFromQualityReport(serialNumber: string):
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/portal/removeSiteEasyFromQualityReport`;

        let data: any = {
            serialNumber: serialNumber
        };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    updateSiteEasyQualityReportInfo(updatedInfo: UpdateSiteEasyQualityReportInfo):
    Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/portal/updateSiteEasyQualityReportInfo`;

        return this.requestService.post<any>(url, updatedInfo).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }
    
    getTEK822QualityReport(): Promise<TEK822QualityReportRow[]> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/tek822QualityReport`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/tek822QualityReport`;

        return this.requestService.get<TEK822QualityReportRow[]>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    addTEK822ToQualityReport(device: AddTEK822DeviceSettings): Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/addTEK822ToQualityReport`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/addTEK822ToQualityReport`;

        return this.requestService.post<any>(url, device).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    removeTEK822FromQualityReport(device: TEK822QualityReportRow ): Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/removeTEK822FromQualityReport`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/removeTEK822FromQualityReport`;

        let data: any = {
            serialNumber: device.serialNumber
        };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    setTEK822ToPowerOff(device: TEK822QualityReportRow ): Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/setTEK822ToPowerOff`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/setTEK822ToPowerOff`;

        let data: any = {
            serialNumber: device.serialNumber
        };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    updateTek822QualityReportInfo(updatedInfo: UpdateTek822QualityReportInfo): Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/updateTek822QualityReportInfo`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/updateTek822QualityReportInfo`;

        return this.requestService.post<any>(url, updatedInfo).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    resetTek822BatteryStatus(device: TEK822QualityReportRow ): Promise<any> {

        let url = `${this.configService.config.mcdApiBasePath}/portal/resetTek822BatteryStatus`;
        if(this.authService.hasExternalKey()) 
            url = `${this.configService.config.mcdApiBasePath}/external/resetTek822BatteryStatus`;

        
        let data: any = {
            serialNumber: device.serialNumber
        };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    getSiteEasyBatteryStatusReport(): Promise<SiteEasyBatteryStatusReport[]> {
        let url =
            `${this.configService.config.mcdApiBasePath}/support/siteEasyBatteryStatusReport`;

            return this.requestService.get<SiteEasyBatteryStatusReport[]>(url).then(response => {

                response.forEach((x) => {
                    x.siteInfoLinks = x.siteInfoLinks.map(
                        (link) => {
                            link.url = this.configService.config.siteInfoBasePath + link.url;
                            return link;
                        })
                });
                return response;
            }, error => {
                throw error;
            });
    }

    getSiteEasyExBatteryStatusReport(): Promise<SiteEasyExBatteryStatusReport[]> {
        let url =
            `${this.configService.config.mcdApiBasePath}/support/siteEasyExBatteryStatusReport`;

            return this.requestService.get<SiteEasyExBatteryStatusReport[]>(url).then(response => {

                response.forEach((x) => {
                    x.siteInfoLinks = x.siteInfoLinks.map(
                        (link) => {
                            link.url = this.configService.config.siteInfoBasePath + link.url;
                            return link;
                        })
                });
                return response;
            }, error => {
                throw error;
            });
    }

    getSiteEasyBatteryOutOfOrder(): Promise<SiteEasyBatteryStatusReport[]> {
        let url =
            `${this.configService.config.mcdApiBasePath}/support/siteEasyOutOfOrder`;

            return this.requestService.get<SiteEasyBatteryStatusReport[]>(url).then(response => {

                response.forEach((x) => {
                    x.siteInfoLinks = x.siteInfoLinks.map(
                        (link) => {
                            link.url = this.configService.config.siteInfoBasePath + link.url;
                            return link;
                        })
                });
                return response;
            }, error => {
                throw error;
            });
    }

    getSiteEasyExBatteryOutOfOrder(): Promise<SiteEasyExBatteryStatusReport[]> {
        let url =
            `${this.configService.config.mcdApiBasePath}/support/siteEasyExOutOfOrder`;

            return this.requestService.get<SiteEasyBatteryStatusReport[]>(url).then(response => {

                response.forEach((x) => {
                    x.siteInfoLinks = x.siteInfoLinks.map(
                        (link) => {
                            link.url = this.configService.config.siteInfoBasePath + link.url;
                            return link;
                        })
                });
                return response;
            }, error => {
                throw error;
            });
    }

    ////////////////////////////////////////////////////////////////
    ///////////////////////// INTEGRATIONS /////////////////////////
    ////////////////////////////////////////////////////////////////


    bintelIntegrationStatusReport():
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/bintelStatusReport`;

        return this.requestService.get<BintelIntegrationStatusReport>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    bintelConfigureDevice(config: ConfigureDeviceSettings):
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/bintelConfigureDevice`;

        return this.requestService.post<any>(url, config).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    packwiseIntegrationStatusReport():
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/packwiseStatusReport`;

        return this.requestService.get<PackwiseIntegrationStatusReport>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    packwiseConfigureDevice(device: PackwiseConfigureDevice):
        Promise<any> {
            let url =
            `${this.configService.config.mcdApiBasePath}/integrations/packwiseConfigureDevice`;

        return this.requestService.post<any>(url, device).then(response => {
            return response;
        }, error => {
            throw error;
        });

        }

    getPackwiseIntegrationCarrierNumber():
        Promise<PackwiseUnusedCarrierNumberList> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/packwiseCarrierNumbers`;

        return this.requestService.get<PackwiseUnusedCarrierNumberList>(url,).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    addPackwiseIntegrationCarrierNumber(carrier: PackwiseRegisterCarrierNumber): 
        Promise<any> {
            let url =
            `${this.configService.config.mcdApiBasePath}/integrations/packwiseRegisterCarrierNumber`;

        return this.requestService.post<PackwiseRegisterCarrierNumber>(url, carrier).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    deletePackwiseCarrierNumber(carrier: PackwiseDeleteCarrierNumber):
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/packwiseDeleteCarrierNumber`;

        return this.requestService.post<any>(url, carrier).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }
    
    KingspanIntegrationStatusReport():
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/kingspan/status/`;

        return this.requestService.get<KingspanIntegrationStatusReport>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    kingspanIntegrationRegistredDevices():
        Promise<KingspanRegistredDeviceList> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/kingspan/devices/registered`;

        return this.requestService.get<KingspanRegistredDeviceList>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }


    kingspanRegisterDeviceConnections(): 
    Promise<KingspanRegisterDeviceConnectionList> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/kingspan/connections`;

        return this.requestService.get<KingspanRegisterDeviceConnectionList>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    addKingspanDevice(device: KingspanRegistredDevices):
        Promise<KingspanRegistredDevices> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/kingspan/devices/register`;

        return this.requestService.post<KingspanRegistredDevices>(url, device).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    removeKingspanDevice(id: number):
        Promise<any> {

        let url =
            `${this.configService.config.mcdApiBasePath}/integrations/kingspan/devices/register/remove`;

            let data: any = {
                id: id
            };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    getAlarmSubscribers():
        Promise<AlarmSubscriberList> {

        let url =
            `${this.configService.config.apiBasePath}/alarmsubscriptions/subscribers`;

        return this.requestService.get<AlarmSubscriberList>(url).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    addAlarmSubscriber(subscriber: AlarmSubscriber):
        Promise<AlarmSubscriber> {

        let url =
            `${this.configService.config.apiBasePath}/alarmsubscriptions/subscriber/register`;

        return this.requestService.post<AlarmSubscriber>(url, subscriber).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    removeAlarmSubscriber(id: number):
        Promise<any> {

        let url =
            `${this.configService.config.apiBasePath}/alarmsubscriptions/subscriber/remove`;

            let data: any = {
                id: id
            };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }


    ////////////////////////////  Warehouse import  //////////////////////////// 

    getWarehouseImportCompanies(): Promise<Company[]> {

        let url =`${this.configService.config.mcdApiBasePath}/warehouseImport/company`;

        return this.requestService.get<Company[]>(url).then(response => {
            return response;
            
        }, error => {
            throw error;
        });
    }

    getWarehouseImportInvoiceResponsibles(companyId: number): 
        Promise<any> {

        let url =`${this.configService.config.mcdApiBasePath}/warehouseImport/invoiceResponsible`;

        let data: any = {
            companyId: companyId
        };

        return this.requestService.post<any>(url, data).then(response => {
            return response;
            
        }, error => {
            throw error;
        });
    }

    getWarehouseImportEquipment(): Promise<Equipment[]> {

        let url =`${this.configService.config.mcdApiBasePath}/warehouseImport/equipment`;

        return this.requestService.get<Equipment[]>(url).then(response => {
            return response;
            
        }, error => {
            throw error;
        });
    }

    validateCarrier(request: ValidateCarrierRequest): 
        Promise<ValidateCarrierResponse[]> {

        let url =`${this.configService.config.mcdApiBasePath}/warehouseImport/validateCarrier`;

        return this.requestService.post<ValidateCarrierResponse[]>(url, request).then(response => {
            return response;
        }, error => {
            throw error;
        });
    }

    setWarehouseImport(settings: WarehouseImportSetting): 
        Promise<any> {

        let url =`${this.configService.config.mcdApiBasePath}/warehouseImport/warehouseImport`;

        return this.requestService.post<any>(url, settings).then(response => {
            return response;
        }, error => {
            throw error.error;
        });
    }



    ngOnDestroy() {
        console.log('MonitoringService - ngOnDestroy()');
        // this._stopPolling.next();
        // this._stopPolling.complete();
    }


}
