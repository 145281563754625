<div class="spinner">
    <div class="spinner__animation" *ngIf="showSpinner">
        <div>
            <div class="lds-wrapper"></div>
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div class="spinner__background">
        <ng-content></ng-content>
    </div>
</div>