import { Injectable, Injector } from '@angular/core';

import {
    HttpResponse,
    HttpErrorResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

    private authService: AuthService;

    constructor(private inj: Injector,
        private router: Router
    ) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // This prevents cyclic dependancy between authService and authTokenInterceptor
        this.authService = this.inj.get(AuthService);

        return next.handle(request).pipe(
            catchError((error: any) => {

                // Check if unauthorized
                if (error.status === 401) {
                    this.router.navigate(['processLogout']);
                }

                throw error;
            }));

    }



}