import { CompilerConfig } from '@angular/compiler';
import { Inject, Injectable } from '@angular/core';
import { CoreConfig } from './core-config.model';

@Injectable()
export class ConfigService {

    public config: CoreConfig = {
        appBasePath: 'http://localhost:4200',
        apiBasePath: 'https://mcd-3s.azurewebsites.net', //'http://localhost:5001', 
        mcdApiBasePath:  'https://localhost:5001', //'https://portalapi.siteinfo.se', //'https://localhost:57865', 'https://portalapi.siteinfo.se' ,// '',
        authApiBasePath:  'https://auth.siteinfo.se', //'http://localhost:5000'
        siteInfoBasePath: 'https://www.siteinfo.se'
    };

    constructor(
        @Inject('HOSTNAME') private hostname: string
    ) {

        console.log('## Host ##', hostname);

        // Prod
        if (this.hostname.indexOf('portal.mcd.se') > -1) {
            this.config = {
                appBasePath: 'https://portal.mcd.se',
                apiBasePath: 'https://mcd-3s.azurewebsites.net',
                mcdApiBasePath: 'https://portalapi.siteinfo.se',                
                authApiBasePath: 'https://auth.siteinfo.se',
                siteInfoBasePath: 'https://www.siteinfo.se'
            };
        }

    }

}