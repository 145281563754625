import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { dynamicSort } from 'src/app/shared/functions/sort';
import { SupportContractSensorReportRow } from '../../models/support-contract-sensor-report.model';
import { SupportService } from '../../services/support.service';
import { HideSensorModalComponent } from '../hide-sensor-modal/hide-sensor-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SensorStatusListFilter } from '../../models/sensor-status-list-filter.model';

@Component({
    selector: 'outdated-sensors',
    templateUrl: './outdated-sensors.component.html',
})
export class OutdatedSensorsComponent implements OnInit {

    public reportRows: SupportContractSensorReportRow[];
    public isLoading: boolean = true;
    public hasSupport: string = "true";
    private currentOrder: string = 'objectCurrentDate';
    public searchInput: string = "";
    public listFilter = new SensorStatusListFilter();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorNotification: DisplayErrorService,
        private supportService: SupportService,
        public modalService: NgbModal,) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.isLoading = true;

        if(this.hasSupport === "true") {
            this.supportService.getSupportContractSensorReport()
                .then(response => {
                    console.log('## Sensors with support ##', response);
                    this.reportRows = response;
                    this.isLoading = false;
                    this.findCompany();
                }).catch(error => {
                });
        } else {
            this.supportService.getSupportNoContractSensorReport()
            .then(response => {
                console.log('## Sensors without support ##', response);
                this.reportRows = response;
                this.isLoading = false;
                this.findCompany();
            }).catch(error => {
            });
        }  
    }

    findCompany() : void {

        if (!this.searchInput) {
            return
        }

        var input = this.searchInput.toLowerCase();

        for(var i = 0; i< this.reportRows.length; i++) {
            if(!this.reportRows[i].companyName.toLowerCase().includes(input))
            {
                this.reportRows.splice(i, 1);
                i--;
            }
        }
    }

    orderBy(property: string, skipFlip: boolean = false) {

        if(this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if(!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ?
                property : '-' + property;
            }
        }

        this.reportRows = this.reportRows.sort(dynamicSort(this.currentOrder));
    }

    sensorIcon(sensor: SupportContractSensorReportRow): string {
        switch (sensor.objectMessageStatusID) {
            case 6:
                return 'fa-wrench';
            case 5:
                return 'fa-exclamation-circle';
            case 4:
                return 'fa-phone fa-flip-horizontal';
            case 3:
                return 'fa-phone-slash fa-flip-horizontal';
            case 2:
                return ''; // ?
            case 1:
                return ''; // ?
            default:
                return '';
        }
    }

    hasLinks(sensor: SupportContractSensorReportRow) {
        if(!sensor.siteInfoLinks || sensor.siteInfoLinks.length == 0)
            return false;
        return true;
    }

    hasMultipleLinks(sensor: SupportContractSensorReportRow) {
        return sensor.siteInfoLinks.length > 1;
    }

    hasSupportContract(): boolean {
        if(this.hasSupport === "true") {
            return true;
        }
        return false;  
    }

    hideSensor(siteId: number){

        const modalRef = this.modalService.open(HideSensorModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.result.then((date: Date) => {

            //Adding margin for UTC time
            let daysToAdd = 1;
            date.setDate(date.getDate() + daysToAdd);

            this.listFilter.siteId = siteId;
            this.listFilter.dateHideUntil = date;

            this.supportService.setSensorStatusListFilter(this.listFilter).then(() => {
                this.refresh();
            }, (response) => {
                this.errorNotification.display(response.error);
            });
        }).catch(response => {

        });
    }

    resetDate(siteId: number) {   

        this.supportService.deleteSensorStatusListFilter(siteId).then(() => {
            this.refresh();
        }).catch(error => {
            console.log(error);
        });        
    }

}




