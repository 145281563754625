import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KingspanRegistredDevices, KingspanRegistredDeviceList } from 'src/app/support/models/kingspan-integration-registred-devices.model';
import { SupportService } from 'src/app/support/services/support.service';
import { AddDeviceModalComponent } from 'src/app/support/siteEasy-quality-report/add-device-modal/add-device-modal.component';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html'
})
export class KingspanDeviceListComponent implements OnInit {

  public devices: KingspanRegistredDevices[] = [];

    public isLoading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public supportService: SupportService) {
            
    }

  ngOnInit(): void {
    console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.kingspanIntegrationRegistredDevices()
                    .then(response => {
                        console.log('## Kingspan Integration Registred Devices ##', response);
                        this.devices = response.devices;
                        this.isLoading = false;
                    }).catch(error => {
                    });
            });
  }

  goToAddDevice(): void {
    this.router.navigateByUrl('integrations/kingspan/register-device');
  }

  removeDevice(device: KingspanRegistredDevices): void {

    this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;
                
                this.supportService.removeKingspanDevice(device.id)
                    .then(response => {
                        console.log('## Kingspan Remove Device ##', response);
                        this.ngOnInit();
                    }).catch(error => {
                    });
            });


  }

}


