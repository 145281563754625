export class LoginState {
    returnUrl: string;
    state: string;
}

export class CallbackResult {
    returnUrl: string
}

export class AuthenticationConfiguration {

    clientId: string;
    scope: string;
    responseType: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    userInfoUri: string;
    authenticateUrl: string;
    logoutUri: string;

    constructor() {
    }
}

export class AuthenticatedUserData {

    accessToken: string;
    idToken: string;
    externalKey: string;

    constructor() {

    }
}

export class AuthenticatedUser {
    nbf: number; 
    exp: number;
    iss: string; 
    aud:[];
    client_id: string; 
    sub: string;
    auth_time: number; 
    idp: string; 
    given_name: string; 
    role: string; 
    email:string;
    scope:[];
    amr:[];
}
