import { Injectable } from '@angular/core';
import {
    AuthenticationConfiguration,
    LoginState,
    CallbackResult,
    AuthenticatedUserData,
    AuthenticatedUser
} from './auth.model';

import { RequestService } from './../services/request.service';
import { HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../core-config.service';


@Injectable()
export class AuthService {

    authConfig: AuthenticationConfiguration;
    externalKey: string = ''; // This session based key for external parties to access portal functionality

    constructor(
        private requestService: RequestService,
        private configService: ConfigService
    ) {

        // openid is used together with id_token
        // id_token can be used to retrieve access token, but we get both at the same time
        // access token is not designed to have info about the user
        this.authConfig = {
            clientId: 'mcd-portal',
            scope: 'profile mcd-internal-portal-api mcd-public-portal-api roles openid',
            responseType: 'id_token token',
            redirectUri: this.configService.config.appBasePath + '/processLogin',
            postLogoutRedirectUri: this.configService.config.appBasePath + '/processLogout',
            userInfoUri: this.configService.config.authApiBasePath + '/connect/userinfo',
            authenticateUrl: this.configService.config.authApiBasePath + '/connect/authorize',
            logoutUri: this.configService.config.authApiBasePath + '/connect/endsession'
        };
    }

    storeLoginState(state: LoginState) {
        localStorage.setItem('mcdPortal_loginAttempt', JSON.stringify(state));
    }

    getLoginState(state: string): LoginState | null {
        let loginState: LoginState;
        let json: string | null = localStorage.getItem('mcdPortal_loginAttempt');
        if (json == null) {
            console.warn('No login attempt found.');
            throw 'No login attempt found.';
        }

        loginState = JSON.parse(json);
        if (loginState.state !== state) {
            console.warn('State does not match, return null.');
            return null;
        }

        return loginState;
    }

    doLogin() {
        let nonce = this.generateRandomString(16); // Can only be used for one request
        let state = this.generateRandomString(16); // Check this return value to prevent CSRF, can also roundtrip app state.

        let loginState: LoginState = {
            state: state,
            returnUrl: ''
        };

        this.storeLoginState(loginState);

        //        ?response_type=token&client_id=siteinfo_api&redirect_uri=https%3A%2F%2Ftestapi.siteinfo.se%2Foauth2-redirect.html&scope=api&state=VGh1IE9jdCAwMSAyMDIwIDA5OjMxOjMwIEdNVCswMjAwIChjZW50cmFsZXVyb3BlaXNrIHNvbW1hcnRpZCk%3D

        var url = this.authConfig.authenticateUrl + '?' +
            'client_id=' + encodeURIComponent(this.authConfig.clientId) +
            '&scope=' + encodeURIComponent(this.authConfig.scope) +
            '&response_type=' + encodeURIComponent(this.authConfig.responseType) +
            '&redirect_uri=' + encodeURIComponent(this.authConfig.redirectUri) +
            '&state=' + state +
            '&nonce=' + nonce;

        window.open(url, '_self');

    }

    storeAuthData(authData: AuthenticatedUserData): void {
        localStorage.setItem('mcdPortal_authData', JSON.stringify(authData));
    }

    getAuthData(): AuthenticatedUserData | null {
        let authData: AuthenticatedUserData;
        let json: string | null = localStorage.getItem('mcdPortal_authData');
        if (json == null) {
            return null;
        }
        authData = JSON.parse(json);
        return authData;
    }

    getUser(): string {

        if(this.hasExternalKey())
            return "External user";

        let authData: AuthenticatedUserData;
        let json = localStorage.getItem('mcdPortal_authData');
        authData = JSON.parse(json);

        let accessToken = authData.accessToken;
        accessToken = accessToken.split(".")[1];
        accessToken = atob(accessToken);
  
        let userAuthentication: AuthenticatedUser;
        userAuthentication = JSON.parse(accessToken);

        let userName = userAuthentication.given_name;
        return userName;
        
    }

    handleCallback(idToken: string, accessToken: string, state: string): Promise<CallbackResult> {
        var promise: Promise<CallbackResult> = new Promise((resolve, reject) => {

            let headers = new HttpHeaders();
            headers = headers.set('Authorization', 'Bearer ' + accessToken);

            //requires openid scope
            this.requestService.get(this.authConfig.userInfoUri, headers).then((userData) => {

                console.log('userinfo', userData);

                var loginState = this.getLoginState(state);
                if (!loginState) {
                    reject();
                    return;
                }

                var authData = new AuthenticatedUserData();
                authData.accessToken = accessToken;
                authData.idToken = idToken;

                this.storeAuthData(authData)

                let result = new CallbackResult();
                result.returnUrl = loginState.returnUrl;
                resolve(result);

            }, (error) => {
                console.warn('Could not load user info from auth server.', error);
                throw error;
            })

        });
        return promise;
    }

    generateRandomString(length: number): string {
        var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
        let result = '';

        while (length > 0) {
            var bytes = new Uint8Array(16);
            var random = window.crypto.getRandomValues(bytes);

            random.forEach(function (c) {
                if (length == 0) {
                    return;
                }
                if (c < charset.length) {
                    result += charset[c];
                    length--;
                }
            });
        }

        return result;
    }

    doLogout(): void {
        let idToken = this.getAuthData().idToken; 

        var url = this.authConfig.logoutUri + '?' +
            'id_token_hint=' + encodeURIComponent(idToken) +
            '&post_logout_redirect_uri=' + encodeURIComponent(this.authConfig.postLogoutRedirectUri);

        // Since we send id token hint we can remove the local session now
        this.clearData();

        // end sso session
        window.open(url, '_self');
    }

    clearData(): void {
        localStorage.removeItem('mcdPortal_authData');
    }

    getToken(): string {
        let authData: AuthenticatedUserData | null = this.getAuthData();
        if (authData != null) {
            return authData.accessToken;
        }
        else {
            return '';
        }
    }

    setExternalKey(key: string): void {
        if(!this.externalKey && key)          
            this.externalKey = key;
    }

    getExternalKey(): string {
        return this.externalKey;
    }

    hasExternalKey(): boolean {
        return this.externalKey && this.externalKey.length > 0;
    }

    isAuthenticated(): boolean {
        // let authData = this.getAuthData();
        // if (authData == null) {
        //     return false;
        // }
        // if (authData.validTo.getTime() < Date.now()) {
        //     this.clearData();
        //     return false;
        // }

        return true;
    }

}
