import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'display-error-modal',
    templateUrl: './display-error-modal.component.html',
    styleUrls: ['./display-error-modal.component.scss']
})

export class DisplayErrorModalComponent {

    @Input() public message: string = 'An error has occured.';
    @Input() public error: any;

    constructor(public modal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    close() {
        this.modal.close();
    }

    hasErrorObject(): boolean {
        if (this.error !== null && this.error !== undefined)
            return true;
        else
            return false;
    }

    errorString() : string {

        if(this.error.error) {
            let problemDetails = this.error.error;
            return problemDetails.detail;
        }

        if(this.error.detail) 
            return this.error.detail;
            
        if(this.error.message)
            return this.error.message; 
            
        return this.message;

    }

}
