import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html',
    // styleUrls: ['./base.component.scss']
})
export class ExternalComponent {

    pageInfo: Data = Object.create(null);

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,        
        private router: Router,
        private titleService: Title
    ) {

        // Store external key from query string
        this.authService.setExternalKey(this.activatedRoute.snapshot.queryParams['ek']);

        console.log("Start external bread crumb");
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .pipe(filter((route) => route.outlet === "primary"))
            .pipe(mergeMap((route) => route.data))
            .subscribe((event: Data) => {
                this.titleService.setTitle(event["title"]);
                this.pageInfo = event;

                console.log("pageinfo", this.pageInfo);

                this.pageInfo.breadcrumb = this.pageInfo.breadcrumb ?? (this.pageInfo.title ? true : false);

            });

    }

    ngOnInit() {

    }

}
