import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteLink } from 'src/app/core/models/site-link.model';

@Component({
    selector: 'goto-site-modal',
    templateUrl: './goto-site-modal.component.html'
})

export class GotoSiteModal {

    @Input() public links: SiteLink[];
    busy: boolean = true;

    constructor(public modal: NgbActiveModal) { }

    ngOnInit(): void {
        console.log('ngOnInit()');
        console.log('links', this.links);
    }

    cancel() {
        this.modal.dismiss();
    }

}
