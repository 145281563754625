import { Component, OnInit } from '@angular/core';
import { KingspanRegistredDevices, KingspanRegisterDeviceForm } from 'src/app/support/models/kingspan-integration-registred-devices.model';
import { SupportService } from 'src/app/support/services/support.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { KingspanRegisterDeviceConnection } from 'src/app/support/models/kingspan-integration-registred-devices.model';

@Component({
  selector: 'register-kingspan-device',
  templateUrl: './register-device.component.html'
})
export class RegisterKingspanDeviceComponent implements OnInit {

  public connection: KingspanRegisterDeviceConnection[] = [];
  public registerDeviceForm = new KingspanRegisterDeviceForm();
  public device = new KingspanRegistredDevices();
  public isLoading = true;
  
  constructor(
        public route: ActivatedRoute,
        public router: Router,
        public supportService: SupportService,
        private errorNotification: DisplayErrorService,

  ) 
   {}

  ngOnInit(): void {

    console.log("ngOnInit()");

        this.route.paramMap
            .subscribe(params => {

                this.isLoading = true;

                this.supportService.kingspanRegisterDeviceConnections()
                    .then(response => {
                        console.log('## Kingspan Register Device Connections ##', response);
                        this.connection = response.connections;
                        this.isLoading = false;
                    }).catch(error => {
                    });
            });
  }

  registerDevice() : void {

    this.device.siteInfoGuid = this.registerDeviceForm.siteInfoGuid;
    this.device.kingspanSignalmanId = Number(this.registerDeviceForm.kingspanSignalmanId);
    this.device.kingspanAllocationId = Number(this.registerDeviceForm.kingspanAllocationId);
    this.device.connectionId = Number(this.registerDeviceForm.connectionId);

        this.supportService.addKingspanDevice(this.device)
        .then(response => {            
            alert('New device registered');
            this.router.navigate(['/integrations/kingspan/device-list']); 
            
        }).catch(error => {
            this.errorNotification.display(error);
        });   
  }

}
