<div class="modal-header">
    <h5>Hide sensor on Dashboard</h5>
</div>
<div class="modal-body">
    <mat-card class="demo-inline-calendar-card">
        <mat-calendar [(selected)]="selectedDate"></mat-calendar>
    </mat-card>
    <div class="clearfix mt-5">
        <button class="btn btn-primary btn float-right" id="confirmButton" (click)="save()">Save</button>
        <button class="btn btn-secondary btn float-right mr-1" (click)="cancel()">Cancel</button>
    </div>
</div>


    
