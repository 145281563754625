<div class="col-xs-12">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary float-right" (click)="refresh()">Refresh</button>
            <button class="btn btn-primary mr-3 float-right" [disabled]="isBusy" confirm
                (confirm-action)="clearBuffers()" confirm-message="Do you want to clear all buffers?">
                Clear buffer
            </button>
        </div>
    </div>
</div>
<div class="col-xs-12">
    <div class="card no-border">
        <div class="card-body">
            <spinner [showSpinner]="isLoading">
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead class="thead-light">
                            <tr>
                                <th></th>
                                <th class="col-max-200">Company</th>
                                <th class="col-max-200">Site</th>
                                <th class="col-max-80"></th>
                                <th>Error Type</th>
                                <th>Telecom Operator</th>
                                <th>Phone</th>
                                <th># Channels</th>
                                <th># Values</th>
                                <th>Last Date</th>
                                <th>
                                    <helper text="The daily count.">
                                        Daily Count
                                    </helper>
                                </th>
                                <th>
                                    <helper text="Time error not equal to 0 indicates an error.">
                                        Time Error
                                    </helper>
                                </th>
                                <!-- <th><span ngbTooltip=""
                                        triggers="click:blur"> <i class="fas fa-info-circle"></i></span></th> -->
                            </tr>
                        </thead>
                        <tr *ngFor="let device of reportRows">
                            <td><button class="btn btn-primary btn-sm" (click)="viewDetails(device)">View</button></td>
                            <td class="col-max-200" title="{{device.company}}">{{device.company}}</td>
                            <td title="{{device.site}}" class="col-max-200">
                                <ng-container *ngIf="hasMultipleLinks(device)">
                                    {{device.site}}
                                </ng-container>
                                <ng-conatiner *ngIf="!hasMultipleLinks(device)">
                                    <a title="{{device.site}}" href="{{ device.siteInfoLinks[0].url}}"
                                        target="_blank">{{device.site}}</a>
                                </ng-conatiner>
                            </td>
                            <td class="col-max-80">
                                <goto-site-button *ngIf="hasMultipleLinks(device)" [links]="device.siteInfoLinks">
                                </goto-site-button>
                            </td>
                            <td>{{device.errorType}}</td>
                            <td>{{device.telecomOperator}}</td>
                            <td>{{device.phoneNumber}}</td>
                            <td class="text-center">{{device.noChannels}}</td>
                            <td class="text-center">{{device.noValues}}</td>
                            <td>{{device.lastDate | date:'yyyy-MM-dd HH:mm'}}</td>
                            <td class="text-center">{{device.dailyCount}}</td>
                            <td>{{device.timeError}}</td>
                        </tr>
                    </table>
                </div>
            </spinner>
        </div>
    </div>
</div>