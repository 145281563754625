<div class="modal-header modal-header-confirm">
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="confirm-message">
        {{ message }}
    </div>
</div>
<div class="modal-footer modal-footer-confirm">
    <div class="button-container">       
            <button type="button" class="btn btn-secondary" (click)="cancel()">{{ cancelText }}</button>
            <button type="button" ngbAutofocus class="btn btn-primary" (click)="accept()" >{{ okText }}</button>
    </div>
</div>