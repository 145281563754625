import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login.component';
import { ProcessLoginComponent } from './auth/process-login.component';
import { ProcessLogoutComponent } from './auth/process-logout.component';

@NgModule({
    declarations: [
    ],
    imports: [
        RouterModule.forRoot([
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: 'login', component: LoginComponent },
            { path: 'processLogin', component: ProcessLoginComponent },
            { path: 'processLogout', component: ProcessLogoutComponent },
            
        ])
    ]
})
export class CoreRoutingModule {
}



