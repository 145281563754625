import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/support/services/support.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmSubscriberList, AlarmSubscriber } from '../models/alarm-subscriber.model';


@Component({
	selector: 'app-alarm-subscribers',
	templateUrl: './alarm-subscribers.component.html',
})
export class AlarmSubscribersComponent implements OnInit {

	public alarmSubscriber: AlarmSubscriberList;
	public subscriber: AlarmSubscriber;
	public isLoading = true;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public supportService: SupportService) {

	}

	ngOnInit(): void {

		console.log("ngOnInit()");

		this.route.paramMap
			.subscribe(params => {

				this.isLoading = true;

				this.supportService.getAlarmSubscribers()
					.then(response => {
						console.log('## Alarm subscribers list ##', response);
						this.alarmSubscriber = response;
						this.isLoading = false;
					}).catch(error => {
					});
			});
	}

	goToAddEmail(): void {
		this.router.navigateByUrl('add-alarm-subscribers');
	}

	removeFromEmailList(subscriber: AlarmSubscriber) {

		this.route.paramMap
			.subscribe(params => {

				this.isLoading = true;

				this.supportService.removeAlarmSubscriber(subscriber.id)
					.then(response => {
						console.log('## Remove Alarm Subscribers ##', response);
						this.ngOnInit();
					}).catch(error => {
					});
			});
	}


}
