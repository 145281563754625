import { Component, Inject } from '@angular/core';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent {  
    constructor() {
    }
}
