import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HelperConfigService } from 'src/app/shared/ui-elements/helper/helper-config.service';
import { AuthService } from '../../auth/auth.service';
import { UiService } from '../../services/ui.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent {

    @Output()
    toggleSidebar = new EventEmitter<void>();

    public showSearch = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        public helperConfig: HelperConfigService
    ) {

    }

    toggleHelp() {
        this.helperConfig.toggleTooltips();
    }

    logout() {
        this.authService.doLogout();
    }
}
