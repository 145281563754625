<div class="modal-header">
    <h4 class="modal-title">Go-to Site</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <ul>
        <li *ngFor="let link of links">
            <a href="{{link.url}}" target="_blank">{{link.description}}</a>
        </li>
    </ul>

    <!-- <div class="clearfix">
        <button class="btn btn-primary btn-lg float-right" (click)="cancel()">Cancel</button>
    </div> -->
</div>