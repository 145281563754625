import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-success-modal',
  templateUrl: './display-success-modal.component.html',
  styleUrls: ['./display-success-modal.component.scss']
})
export class DisplaySuccessModalComponent {

    @Input() public okText: string = 'Ok';
    @Input() public message: string = '';

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  accept() {
    this.modal.close(true);
}

}
