import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Session } from 'protractor';
import { interval, Observable, Subject, Subscription, timer } from 'rxjs';
import { catchError, delay, map, reduce, retry, retryWhen, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SessionService } from 'src/app/core/services/session.service';
import { UiService } from 'src/app/core/services/ui.service';
import { DisplayErrorModalComponent } from 'src/app/shared/display-error/display-error-modal.component';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { EtmBatteryQualityReportRow } from '../models/etm-battery-quality-report.model';
import { SupportService } from '../services/support.service';

@Component({
    selector: 'etm-battery-quality-report',
    templateUrl: './etm-battery-quality-report.component.html',
})
export class EtmBatteryQualityReportComponent implements OnInit {

    public reportRows: EtmBatteryQualityReportRow[];
    public isLoading: boolean = true;
    public isBusy = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private errorNotification: DisplayErrorService,
        private sessionService: SessionService,
        private supportService: SupportService,
        public uiService: UiService) {

    }

    ngOnInit(): void {

        this.route.queryParamMap
            .subscribe(params => {

                this.isLoading = true;
                let useCache: boolean = (params.get('useCache') || '') === 'true';

                this.supportService.getEtmBatteryQualityReport(useCache)
                    .then(response => {
                        console.log('## ETM Battery Quality Report ##', response);
                        this.reportRows = response;
                        this.isLoading = false;
                    }).catch(error => {
                        this.errorNotification.display(error);
                    });
            });

    }

    viewDetails(device: EtmBatteryQualityReportRow) {
        this.router.navigate(['etm-battery-quality-report', device.icc]);
    }

    hasMultipleLinks(device: EtmBatteryQualityReportRow) {
        return device.siteInfoLinks.length > 1;
    }

    refresh() {
        this.router.navigate(['etm-battery-quality-report'], { queryParams: { cbs: Math.random().toString(36).substring(7) } });
    }

    clearBuffers() {
        this.isBusy = true;

        this.supportService.clearETMBatteryBuffers().then(
            () => alert('Done.'),
            (response) => {
                this.errorNotification.display(response);
            })
            .finally(() => { this.isBusy = false; });
    }

}




