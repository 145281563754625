import { Component, OnInit } from '@angular/core';
import { dynamicSort } from 'src/app/shared/functions/sort';
import { SensorMessageNotUpdatedReport } from '../models/sensor-message-not-updated-report';
import { SupportService } from '../services/support.service';


@Component({
	selector: 'app-sensor-updated',
	templateUrl: './sensor-message-not-updated.component.html'
})
export class SensorMessageNotUpdatedComponent implements OnInit {

	public reportRows: SensorMessageNotUpdatedReport[];
	public searchForCompanyRows: SensorMessageNotUpdatedReport[];
	public isLoading = true;
	public searchInput = "";
	private currentOrder: string = 'objectCurrentDate';

	constructor(public supportService: SupportService) { }

	ngOnInit(): void {

		this.getSensorMessageNotUpdatedReport();
	}

	getSensorMessageNotUpdatedReport(): void {

        this.isLoading = true;

		this.supportService.getSensorMessageNotUpdatedReport()
		.then(response => {
			this.reportRows = response;	
			this.searchForCompany();
			this.isLoading = false;
		})
		.catch(error => {
			console.log(error)
		})
	}

	searchForCompany() : void {

		if (!this.searchInput) {
			return
		}
		
		var input = this.searchInput.toLowerCase();
		this.searchForCompanyRows = this.reportRows.filter( row => row.companyName.toLowerCase().includes(input));
		this.reportRows = this.searchForCompanyRows; 
    }

	hasLinks(sensor: SensorMessageNotUpdatedReport) {
        if (!sensor.siteInfoLinks || sensor.siteInfoLinks.length == 0)
            return false;
        return true;
    }

    hasMultipleLinks(sensor: SensorMessageNotUpdatedReport) {
        return sensor.siteInfoLinks.length > 1;
    }

    orderBy(property: string, skipFlip: boolean = false) {

        if (this.currentOrder != property) {
            this.currentOrder = property;
        } else {
            // flip sort order
            if (!skipFlip) {
                this.currentOrder = this.currentOrder[0] === '-' ?
                    property : '-' + property;
            }
        }
        this.reportRows = this.reportRows.sort(dynamicSort(this.currentOrder));   
    }

	sensorIcon(sensor: SensorMessageNotUpdatedReport): string {
        switch (sensor.objectMessageStatusID) {
            case 6:
                return 'fa-wrench';
            case 5:
                return 'fa-exclamation-circle';
            case 4:
                return 'fa-phone fa-flip-horizontal';
            case 3:
                return 'fa-phone-slash fa-flip-horizontal';
            case 2:
                return ''; // ?
            case 1:
                return ''; // ?
            default:
                return '';
        }
    }

}
