import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public isBusy: boolean = false;    

    constructor(
        private authService: AuthService) {
    }

    ngOnInit(): void {
    }
    
    doLogin(): void {
        this.isBusy = true;
        this.authService.doLogin();
    }

 }
