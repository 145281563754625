import { Component, OnInit } from '@angular/core';
import { SupportService } from 'src/app/support/services/support.service';
import { PackwiseConfigureDevice } from 'src/app/support/models/packwise-integration-configure-device-model';
import { DisplayErrorService } from 'src/app/shared/display-error/display-error.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-configure-device',
	templateUrl: './configure-device.component.html'
})
export class PackwiseConfigureDeviceComponent implements OnInit {

	public deviceSettings = new PackwiseConfigureDevice();

	constructor(
		public supportService: SupportService,
		public router: Router, 
		private errorNotification: DisplayErrorService,

	) { }

	ngOnInit(): void {
	}


	configureDevice(): void {

		this.supportService.packwiseConfigureDevice(this.deviceSettings)
		.then(response => {            
            alert('Device configured');
            this.router.navigate(['integrations']);           
        }).catch(error => {
            this.errorNotification.display(error);
        });
	}

	goBack(): void {
		this.router.navigateByUrl('integrations');
	}

}
