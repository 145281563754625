import { Component, ElementRef, ViewChild, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddTEK822DeviceSettings } from './add-device.model';
import { AuthService } from 'src/app/core/auth/auth.service';


@Component({
    selector: 'add-tek822-device-modal',
    templateUrl: './add-device-modal.component.html'
})

export class AddTEK822DeviceModalComponent {

    public deviceSettings = new AddTEK822DeviceSettings();
    @ViewChild('snfield') serialNumberField: ElementRef;

    @HostListener('document:keydown.enter', ['$event'])
        onKeydownHandler(event: KeyboardEvent) {  
            document.getElementById('confirmButton').click();   
    }

    constructor(
        public modal: NgbActiveModal,
        public authService: AuthService,

        ) {
        this.deviceSettings.serialNumber = 'SE-2';
        this.deviceSettings.user = this.authService.getUser();
    }

    ngOnInit(): void {
        
    }

    ngAfterViewInit(): void {
        console.log('test');

        this.serialNumberField.nativeElement.focus();
    }

    add() {
        this.modal.close(this.deviceSettings);
    }

    cancel() {
        this.modal.dismiss();
    }

}
