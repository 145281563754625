import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from './ui-elements/spinner/spinner.component';
import { GotoSiteModal } from './goto-site-modal/goto-site-modal.component';
import { GotoSiteButtonComponent } from './goto-site-modal/goto-site-button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmActionComponent } from './confirm-action/confirm-action.component';
import { DisplayErrorModalComponent } from './display-error/display-error-modal.component';
import { DisplayErrorService } from './display-error/display-error.service';
import { HelperComponent } from './ui-elements/helper/helper.component';
import { HelperConfigService } from './ui-elements/helper/helper-config.service';
import { DisplaySuccessModalComponent } from './display-success/display-success-modal.component';

@NgModule({
    imports: [
        HttpClientModule,
        RouterModule,
        FormsModule,
        CommonModule,
        NgbModule
    ],
    providers: [
        DisplayErrorService,
        HelperConfigService
    ],
    declarations: [
        SpinnerComponent,
        GotoSiteModal,
        GotoSiteButtonComponent,
        ConfirmActionComponent,
        DisplayErrorModalComponent,
        HelperComponent,
        DisplaySuccessModalComponent
    ],
    exports: [
        SpinnerComponent,
        GotoSiteModal,
        GotoSiteButtonComponent,
        ConfirmActionComponent,
        DisplayErrorModalComponent,
        HelperComponent
    ]
})
export class SharedModule { }