import { Component, Directive, EventEmitter, HostListener, Injectable, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteLink } from 'src/app/core/models/site-link.model';
import { ConfirmActionModalComponent } from './confirm-action-modal.component';

@Directive({
    selector: '[confirm]'
})

export class ConfirmActionComponent {
   
    @Input('confirm-message') confirmMessage: string;
    @Output('confirm-action') confirmAction: EventEmitter<any> = new EventEmitter();

    constructor(public modalService: NgbModal) { }

    ngOnInit(): void {
        console.log('ngOnInit()');
    }

    @HostListener('click')
    open() {

        console.log('Text', this.confirmMessage);       

        const modalRef = this.modalService.open(ConfirmActionModalComponent,
            { backdrop: 'static', centered: true });

        modalRef.componentInstance.message = this.confirmMessage;
        modalRef.result.then((result) => {
            if(result === true) {
                this.confirmAction.emit();
            }
          }, () => {
          });

    }
}
