import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../core/core-config.service';
import { SharedModule } from '../shared/shared.module';
import { SupportDashboardComponent } from './dashboard/dashboard.component';
import { ObjectStatisticsComponent } from './dashboard/object-statistics/object-statistics.component';
import { SensorListComponent } from './dashboard/sensor-list/sensor-list.component';
import { EtmBatteryDetailsComponent } from './etm-battery-quality-report/details/details.component';
import { EtmBatteryQualityReportComponent } from './etm-battery-quality-report/etm-battery-quality-report.component';
import { BintelConfigureDeviceComponent } from './integrations/bintel/configure-device/configure-device.component';
import { BintelIntegrationOverviewComponent } from './integrations/bintel/overview/overview.component';
import { KingspanDeviceListComponent } from './integrations/kingspan/device-list/device-list.component';
import { RegisterKingspanDeviceComponent } from './integrations/kingspan/register-device/register-device.component';
import { IntegrationsOverviewComponent } from './integrations/overview.component';
import { PackwiseIntegrationOverviewComponent } from './integrations/packwise/overview/overview.component';
import { OutdatedSensorsComponent } from './sensor-list/outdated/outdated-sensors.component';
import { MonitoringService } from './services/monitoring.service';
import { SupportService } from './services/support.service';
import { AddDeviceModalComponent } from './siteEasy-quality-report/add-device-modal/add-device-modal.component';
import { SiteEasyQualityReportComponent } from './siteEasy-quality-report/siteEasy-quality-report.component';
import { SupportRoutingModule } from './support-routing.module';
import { SystemStatusComponent } from './system-status/system-status.component';
import { AddTEK822DeviceModalComponent } from './tek822-quality-report/add-device-modal/add-device-modal.component';
import { TEK822QualityReportComponent } from './tek822-quality-report/tek822-quality-report.component';
import { KingspanPendingDevicesComponent } from './integrations/kingspan/pending-devices/pending-devices.component';
import { AlarmSubscribersComponent } from './alarm-subscribers/alarm-subscribers.component';
import { AddAlarmSubscriberComponent } from './alarm-subscribers/add-alarm-subscriber/add-alarm-subscriber.component';
import { SiteEasyBatteryStatusComponent } from './siteEasy-battery-status/siteEasy-battery-status.component';
import { PackwiseRegisterCarrierNumberComponent } from './integrations/packwise/register-carrier-number/register-carrier-number.component';
import { PackwiseRemoveCarrierNumberComponent } from './integrations/packwise/remove-carrier-number/remove-carrier-number.component';
import { PackwiseConfigureDeviceComponent } from './integrations/packwise/configure-device/configure-device.component';
import { SiteEasyExBatteryStatusComponent } from './siteEasy-Ex-battery-status/siteEasy-Ex-battery-status.component';
import { SensorMessageNotUpdatedComponent } from './sensor-message-not-updated/sensor-message-not-updated.component';
import { UpdateTek822InfoComponent } from './tek822-quality-report/update-tek822-info-modal/update-tek822-info.component';
import { UpdateSiteEasyInfoComponent } from './siteEasy-quality-report/update-siteEasy-info-modal/update-siteEasy-info.component';
import { WarehouseImportComponent } from './warehouse-import/warehouse-import.component';
import { HideSensorModalComponent } from './sensor-list/hide-sensor-modal/hide-sensor-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule} from '@angular/material/card';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        SharedModule,
        SupportRoutingModule,
        NgbModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule 
    ],
    declarations: [
        SupportDashboardComponent,
        SystemStatusComponent,
        SensorListComponent,
        ObjectStatisticsComponent,
        SiteEasyQualityReportComponent,       
        AddDeviceModalComponent,
        TEK822QualityReportComponent,
        AddTEK822DeviceModalComponent,
        EtmBatteryQualityReportComponent,
        EtmBatteryDetailsComponent,
        IntegrationsOverviewComponent,
        BintelIntegrationOverviewComponent,
        BintelConfigureDeviceComponent,
        PackwiseIntegrationOverviewComponent,
        OutdatedSensorsComponent,
        RegisterKingspanDeviceComponent,
        KingspanDeviceListComponent,
        KingspanPendingDevicesComponent,
        AlarmSubscribersComponent,
        AddAlarmSubscriberComponent,
        SiteEasyBatteryStatusComponent,
        PackwiseRegisterCarrierNumberComponent,
        PackwiseRemoveCarrierNumberComponent,
        PackwiseConfigureDeviceComponent,
        SiteEasyExBatteryStatusComponent,
        SensorMessageNotUpdatedComponent,
        UpdateTek822InfoComponent,
        UpdateSiteEasyInfoComponent,
        WarehouseImportComponent,
        HideSensorModalComponent
    ],
    providers: [
        MonitoringService,
        SupportService,
        ConfigService,
    ],
    exports: [
        SupportDashboardComponent,
        SystemStatusComponent,  
    ]
})
export class SupportModule { }