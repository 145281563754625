import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteLink } from 'src/app/core/models/site-link.model';

@Component({
    selector: 'confirm-action-modal',
    templateUrl: './confirm-action-modal.component.html',
    styleUrls: ['./confirm-action-modal.component.scss']
})

export class ConfirmActionModalComponent {
    @Input() public okText: string = 'Ok';
    @Input() public cancelText: string = 'Cancel';
    @Input() public message: string = 'Message';

    constructor(public modal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    accept() {
        this.modal.close(true);
    }

    cancel() {
        this.modal.dismiss();
    }
}
